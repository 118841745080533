//
// Extras pages.scss
//

// Gallery

.categories-filter {
  .list-inline-item {
    position: relative;
    margin-right: 0;
    a {
      display: block;
      color: var(--#{$prefix}body-color);
      font-weight: $font-weight-semibold;
      padding: 8px 15px;
      margin: 7px;
      cursor: pointer;
      border-radius: 4px;
      &.active {
        color: $primary;
        font-weight: $font-weight-bold;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $text-muted;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -2px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.gallery-box {
  position: relative;
  text-align: center;
  overflow: hidden;
  border: 1px solid var(--#{$prefix}border-color);
  box-shadow: 0 2px 3px var(--#{$prefix}gray-100);

  .gallery-container {
    position: relative;
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;

    a {
      display: block;
    }

    .gallery-overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      opacity: 0;
      background: rgba($primary, 0.2);
      visibility: hidden;
      overflow: hidden;
      transition: all 0.4s ease-in-out 0s;

      .overlay-caption {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
      }
    }
  }
  .gallery-img {
    transition: all 0.2s ease-in-out;
  }
  .box-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -28px;
    background-color: var(--#{$prefix}card-bg);
    transition: all 0.2s;

    .title {
      font-size: 14px;
      margin-bottom: 4px;
    }
    .post {
      margin: 0;
      opacity: 0;
      transition: all 0.2s;
      color: $text-muted;
    }
  }

  &:hover {
    .box-content {
      bottom: 0;

      .post {
        opacity: 1;
      }
    }
    .gallery-img {
      transform: translateY(-28px);
    }
    .gallery-overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}

// profile

.profile-bg {
  height: 210px;
  // background-image: url("../../../images/profile-bg.jpg");
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: transparent;
}

// FAQs

.faq-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // background-image: url("../../../images/profile-bg.jpg");
}

// pricing

.pricing-box {
  border-top: 2px solid $primary;

  .pricing-features {
    color: $text-muted;
    li {
      padding: 4px 0;
    }
  }
}

// error

.error-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 130px;
  @media (max-width: 1199.98px) {
    font-size: 72px;

    .persona-lg {
      width: 48px;
    }
  }
}
