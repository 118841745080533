//
// File manager.scss
//

.filemanager-sidebar {
  @media (min-width: 1200px) {
    min-width: 230px;
    max-width: 230px;
  }

  @media (min-width: 1366px) {
    min-width: 280px;
    max-width: 280px;
  }

  .alert-info {
    .btn-close {
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
    }
  }
}

.categories-list {
  padding: 4px 0;
  li {
    a {
      display: block;
      padding: 8px 12px;
      color: $body-color;
      font-weight: $font-weight-medium;
    }

    &.active {
      a {
        color: $primary;
      }
    }

    ul {
      padding-left: 14px;
      li {
        a {
          padding: 4px 12px;
          color: $text-muted;
          font-size: 13px;
          font-weight: $font-weight-normal;
        }
      }
    }
  }
}
