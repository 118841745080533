$primary: #19273f;
$secondary: #19273f;
$success: #95c602;
$danger: #f72585;
$yellow: #f2b619;
$light: #fff;
$dark: #0a2240;
$black: #0a2240;
$grey: #3c3d44;
$white: #f8f8f8;
$red: #f72585;
$green: #00bcf6;
$blue: #0072c6;

$primary-font: 'Inter';

$header-height: 76px;
$sidebar-width: 76px;
$expanded-sidebar-width: 250px;
$panel-width: 466px;
$expanded-panel-width: 800px;

$mobile-screen: 959px;

$border-color-light: #dee1e6;
