//
// forms.scss
//

[type='tel'],
[type='url'],
[type='email'],
[type='number'] {
  &::placeholder {
    text-align: left
      /*rtl: right*/
    ;
  }
}

.form-check {
  position: relative;
  text-align: left
    /*rtl: right*/
  ;
}

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}

// checkbox

.form-checkbox-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type='checkbox'] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: '\F012C';
        font-family: 'Material Design Icons';
        top: -4px !important;
        left: 1px;
        /*rtl: -4px */
        font-size: 16px;
        color: $dark;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type='checkbox'] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: '\F0765';
        font-family: 'Material Design Icons';
        top: 6px !important;
        left: 4px;
        font-size: 6px;
      }
    }
  }
}

// checkbox color

@each $color,
$value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;

        &:after {
          color: $value;
        }
      }
    }
  }
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md {
  font-size: 20px;
  min-height: 26px;
  line-height: 26px;

  .form-check-label {
    font-size: $font-size-base;
    vertical-align: middle;
  }
}

.form-switch-lg {
  font-size: 26px;
  min-height: 36px;
  line-height: 36px;

  .form-check-label {
    font-size: $font-size-base;
    vertical-align: middle;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

// card radio

.card-radio {
  background-color: $card-bg;
  border: 2px solid $card-border-color;
  border-radius: $border-radius;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.card-radio-label {
  display: block;
}

.card-radio-input {
  display: none;

  &:checked+.card-radio {
    border-color: $primary !important;
  }
}

.form-filter-label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
}

.action-btn {
  color: #ee8134;
  font-size: 13px;
}

.time-picker__wrapper {
  .ngb-tp .btn {
    padding: 0;
  }
}

.api-key-dialog {
  .copy-key-value {
    color: #989da8;
  }

  .key-value {
    max-height: 100px;
    min-height: 75px;
  }
}

.drop-file-wrapper {
  .drop-files-zone {
    border: none;
    background: none !important;

    .drop-files-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: #FAFAFBFF;
      border-radius: 6px;
      border: 2px dashed #BDC1CAFF;
      padding: 30px 0;
    }
  }

  &.active {
    .drop-files-content {
      border-color: #0a58ca;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

[data-layout-mode='dark'] {
  .form-switch {

    .form-check-input,
    .form-check-input:focus {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$gray-400}'/></svg>"));
    }
  }

  .form-select {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-400}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
  }

  tag-input-form {
    input {
      background-color: #033350;
      color: #ffffff
    }
  }
  .form-check-input{
    color: #ffffff;
    border-color: #ffffff;
  }
  .drop-file-wrapper {
    .drop-files-zone {
      .drop-files-content {
        background-color: #033350;
        border-color: #043a5a;
      }
    }

    &.active {
      .drop-files-content {
        border-color: #0a58ca;
      }
    }
  }

  button.refresh-button {
    background-color: #043a5a;
  }
}