//
// _menu.scss
//

.metismenu {
  margin: 0;

  li {
    display: block;
    width: 100%;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block;
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
  }
}

.vertical-menu {
  width: $sidebar-width;
  z-index: 1005;
  background: $sidebar-bg;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  border-right: 1px solid $sidebar-border-color;

  .vertical-menu-btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.main-content {
  margin-left: $sidebar-width;
  overflow: hidden;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}

.sidebar-menu-scroll {
  height: calc(100% - #{$header-height});
  margin-top: $header-height;
}

#sidebar-menu {
  padding: 10px 0 30px 0;

  .mm-active {
    > .has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .has-arrow {
    &:after {
      content: '\F0140';
      font-family: 'Material Design Icons';
      display: block;
      transition: transform 0.2s;
      font-size: 1rem;
      position: absolute;
      right: 15px;
      top: 9px;
    }

    + ul .has-arrow {
      &:after {
        right: 25px;
        top: 7px;
      }
    }
  }

  .metismenu {
    .menu-title {
      &::after {
        display: none;
      }
    }
    .menu-item {
      margin-left: 3px;
    }
  }

  ul {
    li {
      a {
        display: flex;
        padding: 0.5rem 1rem;
        color: $sidebar-menu-item-color;
        position: relative;
        font-weight: 400;
        transition: all 0.4s;
        margin: 0 10px;
        border-radius: 3px;
        font-size: 0.8rem;
        font-family: $font-family-secondary;
        align-items: center;

        .nav-icon {
          svg {
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            color: $sidebar-menu-item-icon-color;
            transition: all 0.3s;
            top: -1px;
          }
        }

        &:hover {
          color: $sidebar-menu-item-hover-color;
          .nav-icon {
            color: $sidebar-menu-item-hover-color;
          }
        }
      }

      .badge {
        margin-left: auto;
      }

      ul.sub-menu {
        padding: 0;

        li {
          a {
            padding: 0.5rem 1.5rem 0.5rem 3.6rem;
            font-weight: 400;
            color: $sidebar-menu-sub-item-color;
            margin: 0;
            background-color: transparent;
            font-size: 14px;
            font-family: $font-family-base;
            margin-right: 10px;

            &:hover {
              color: $sidebar-menu-item-hover-color;

              &:before {
                border-color: $primary;
                background-color: $primary;
              }
            }

            &:before {
              content: '';
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: 0 0;
              border: 1px solid $sidebar-menu-sub-item-color;
              position: absolute;
              left: 35px;
              top: 16px;
              transition: all 0.4s ease-in-out;
            }
          }

          ul.sub-menu {
            padding: 0;

            li {
              a {
                padding: 0.4rem 1.5rem 0.4rem 4.5rem;

                &:before {
                  left: 55px;
                  top: 15px;
                }
              }
            }
          }
        }
      }

      &.mm-active {
        color: $sidebar-menu-item-active-color;

        > a {
          background-color: $sidebar-menu-item-active-bg-color;
          color: $sidebar-menu-item-active-color;

          .nav-icon {
            color: $sidebar-menu-item-active-color !important;
          }
        }

        .mm-show {
          .mm-active {
            > a {
              color: $sidebar-menu-sub-item-active-color !important;

              &:before {
                border-color: $primary !important;
                background-color: $primary !important;
              }
            }
          }
        }
      }
    }
  }
}

.menu-title {
  padding: 12px 25px !important;
  letter-spacing: 0.08em;
  pointer-events: none;
  cursor: default;
  font-size: 10px;
  text-transform: uppercase;
  color: $sidebar-menu-item-icon-color;
  font-weight: $font-weight-semibold;
  opacity: 0.6;
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
    top: $header-height;

    .sidebar-menu-scroll {
      height: 100%;
      margin-top: 0;
    }

    .navbar-brand-box,
    .vertical-menu-btn {
      display: none;
    }
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable {
    .vertical-menu {
      display: block;
    }
  }

  body,
  body[data-sidebar-size='sm'] {
    #page-topbar {
      left: 0 !important;
    }
  }
}

// Enlarge menu
body[data-sidebar-size='sm'] {
  @media (min-width: 769px) {
    min-height: 1850px;
  }

  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    width: $sidebar-collapsed-width !important;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
    }
  }

  // topbar
  #page-topbar {
    left: $sidebar-collapsed-width;
  }

  // Side menu
  .vertical-menu {
    position: absolute;
    width: $sidebar-collapsed-width !important;
    z-index: 1001;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }

    .simplebar-scrollbar,
    .vertical-menu-btn {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .badge {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-arrow:after {
        transform: rotate(-90deg);
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 15px 13px;
            transition: none;
            display: block;

            &:hover,
            &:active,
            &:focus {
              color: $sidebar-menu-item-hover-color;
            }

            i {
              font-size: 1.3rem;
              text-align: center;
              min-width: 50px;
              padding-bottom: 0;
            }

            span {
              display: none;
              padding-left: 25px;
            }

            &.has-arrow {
              &:after {
                display: none;
              }
            }

            .nav-icon {
              top: -2px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(180px + #{$sidebar-collapsed-width});
              color: $primary;
              background-color: $sidebar-menu-item-active-bg-color;
              transition: none;
              box-shadow: inset 0 3px 10px 0 rgba(154, 161, 171, 0.2);

              .nav-icon {
                color: $primary;
              }

              span {
                display: inline;
              }
            }

            > ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
              }

              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 190px;
                z-index: 6;
                color: $sidebar-menu-sub-item-color;

                &:hover {
                  color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: 9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $gray-100;
            }
          }
        }
      }

      ul li {
        ul.sub-menu {
          li a {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

body[data-sidebar='dark'] {
  .vertical-menu {
    background: $sidebar-dark-bg;
    border-color: $sidebar-dark-bg;

    .vertical-menu-btn {
      color: $sidebar-dark-menu-item-color;
    }
  }

  #sidebar-menu {
    ul {
      li {
        a {
          color: $sidebar-dark-menu-item-color;

          .nav-icon {
            svg {
              color: $sidebar-dark-menu-item-icon-color;
            }
          }

          &:hover {
            svg {
              color: $sidebar-dark-menu-item-hover-color;
            }

            .nav-icon {
              svg {
                color: $sidebar-dark-menu-item-hover-color;
              }
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: $sidebar-dark-menu-sub-item-color;
              background-color: transparent;

              &:before {
                border-color: $sidebar-dark-menu-sub-item-color;
              }

              &:hover {
                color: $sidebar-dark-menu-item-hover-color;
                &:before {
                  background-color: $sidebar-dark-menu-item-hover-color;
                  border-color: $sidebar-dark-menu-item-hover-color;
                }
              }
            }
          }
        }

        &.mm-active {
          color: $sidebar-dark-menu-item-active-color !important;

          > a {
            background-color: $sidebar-dark-menu-item-active-bg-color;
            color: $sidebar-dark-menu-item-active-color !important;

            .nav-icon {
              svg {
                color: $sidebar-dark-menu-item-active-color !important;
              }
            }
          }

          .mm-show {
            .mm-active {
              > a {
                color: $sidebar-dark-menu-item-active-color !important;

                &:before {
                  border-color: $sidebar-dark-menu-item-active-color !important;
                  background-color: $sidebar-dark-menu-item-active-color !important;
                }
              }
            }
          }
        }
      }
    }
  }

  // Enlarge menu
  &[data-sidebar-size='sm'] {
    min-height: 1300px;
    // Side menu
    .vertical-menu {
      // Sidebar Menu
      #sidebar-menu {
        > ul {
          > li {
            &:hover {
              > a {
                background: lighten($sidebar-dark-bg, 3%);
                color: $sidebar-dark-menu-item-hover-color;
                .nav-icon {
                  svg {
                    color: $sidebar-dark-menu-item-hover-color;
                  }
                }
              }

              > ul {
                a {
                  color: $sidebar-dark-menu-sub-item-color;
                  &:hover {
                    svg {
                      color: $sidebar-dark-menu-item-hover-color;
                    }
                  }
                }
              }
            }
          }

          ul {
            background-color: $sidebar-dark-bg;
          }
        }
      }
    }
  }

  .menu-title {
    color: $sidebar-dark-menu-item-color;
  }
}

body[data-layout='horizontal'] {
  .main-content {
    margin-left: 0 !important;
  }
}

// Compact Sidebar

body[data-sidebar-size='md'] {
  .navbar-brand-box {
    width: $sidebar-width-sm;
    @media (max-width: 991.98px) {
      width: auto;
    }
  }
  .vertical-menu {
    width: $sidebar-width-sm;
    text-align: center;

    .has-arrow:after,
    .badge {
      display: none !important;
    }

    .vertical-menu-btn {
      display: none;
    }
  }

  .main-content {
    margin-left: $sidebar-width-sm;
  }
  #page-topbar,
  .footer {
    left: $sidebar-width-sm;
    @media (max-width: 991.98px) {
      left: 0;
    }
  }

  #sidebar-menu {
    ul li {
      &.menu-title {
        background-color: darken($sidebar-bg, 20%);
      }
      .menu-item {
        margin-left: 0px;
      }
      a {
        font-size: 0.8rem;
        padding: 0.75rem 1.1rem;
        display: block;
        .nav-icon {
          width: 20px;
          height: 20px;
          display: block !important;
          margin: 0 auto 5px auto !important;
          padding-bottom: 0.2em;
          top: -2px;
        }
      }
      ul.sub-menu {
        li a {
          padding-left: 1.5rem;
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &[data-sidebar='dark'] {
    #sidebar-menu {
      ul li {
        &.menu-title {
          background-color: lighten($sidebar-dark-bg, 2%);
        }
      }
    }
  }

  &:not(.vertical-collpsed) {
    .navbar-header .vertical-menu-btn {
      display: block;
    }
  }
  &[data-sidebar-size='sm'] {
    .main-content {
      margin-left: $sidebar-collapsed-width;
    }
    .vertical-menu {
      #sidebar-menu {
        text-align: left;
        > ul {
          > li {
            > a {
              .nav-icon {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    #page-topbar,
    .footer {
      left: $sidebar-collapsed-width;
    }
  }
}

// colored sidebar

body[data-sidebar='brand'] {
  .vertical-menu {
    background-color: $primary;
    border-color: $primary;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-image: url('../../../images/profile-bg.jpg');
      // background-size: cover;
      // background-position: center;
      // background-repeat: no-repeat;
      opacity: 0.075;
    }

    .vertical-menu-btn {
      color: rgba($white, 0.8);
    }
  }

  .navbar-brand-box {
    background-color: $primary;
    .logo-dark {
      display: none;
    }
    .logo-light {
      display: block;
    }
  }

  #sidebar-menu {
    ul {
      li {
        &.menu-title {
          color: rgba($white, 0.7);
        }

        a {
          color: rgba($white, 0.7);
          .nav-icon {
            svg {
              color: rgba($white, 0.7);
            }
          }

          &:hover {
            svg {
              color: $white;
            }

            .nav-icon {
              svg {
                color: $white;
              }
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: rgba($white, 0.6);

              &:before {
                border-color: rgba($white, 0.6);
              }

              &:hover {
                color: $white;
                &:before {
                  background-color: $white;
                  border-color: $white;
                }
              }
            }
          }
        }

        .badge {
          background-color: $white;
        }

        &.mm-active {
          color: $white !important;

          > a {
            background: none;
            color: $white !important;

            .nav-icon {
              color: $white !important;
            }
          }

          .mm-show {
            .mm-active {
              > a {
                color: $white !important;

                &:before {
                  border-color: $white !important;
                  background-color: $white !important;
                }
              }
            }
          }
        }
      }
    }
  }

  &[data-topbar='light'] {
    .navbar-brand-box {
      background-color: $primary;
      .logo-dark {
        display: none;
      }
      .logo-light {
        display: block;
      }
    }
  }

  &[data-sidebar-size='sm'] {
    .vertical-menu {
      #sidebar-menu {
        > ul {
          > li {
            &:hover > a {
              background-color: lighten($primary, 2%);
              color: $white;
              .nav-icon {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
