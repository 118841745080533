//
// _horizontal.scss
//

body[data-layout='horizontal'] {
  #page-topbar {
    left: 0;
    box-shadow: none;
  }

  .navbar-brand-box {
    display: block;
    width: auto;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    position: relative;
    @media (min-width: 992px) {
      padding-left: 0;
      margin-right: 10px;
    }
  }

  .page-content {
    margin-top: $header-height;
    padding: calc(55px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
    @media (max-width: 991.98px) {
      padding-top: $grid-gutter-width;
    }
  }

  .navbar-header {
    @media (min-width: 992px) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }

  &[data-sidebar-size='sm'] {
    .navbar-brand-box {
      width: auto !important;
    }
  }
}

.topnav {
  background: var(--#{$prefix}topnav-bg) !important;
  padding: 0 calc(#{$grid-gutter-width} / 2);
  z-index: 100;
  border-top: 1px solid var(--#{$prefix}border-color);
  border-bottom: 1px solid var(--#{$prefix}border-color);

  @media (min-width: 992px) {
    background: $topnav-bg;
  }

  .topnav-menu {
    margin: 0;
    padding: 0;
  }

  .navbar-nav {
    .nav-link {
      font-size: 14.4px;
      position: relative;
      padding: 1rem 1.3rem;
      color: $menu-item-color;
      font-weight: $font-weight-normal;
      i {
        font-size: 15px;
      }
      svg {
        height: 16px;
        width: 16px;
        color: $menu-item-color;
        margin-right: 7px;
        margin-top: -1px;
      }
      &:focus,
      &:hover {
        color: $menu-item-active-color;
        background-color: transparent;
        svg {
          color: $menu-item-active-color;
        }
      }
    }

    .dropdown-item {
      color: $menu-item-color;
      background: transparent;
      &.active,
      &:hover {
        color: $menu-item-active-color;
      }
    }

    .nav-item {
      .nav-link.active {
        color: $menu-item-active-color;
        svg {
          color: $menu-item-active-color;
        }
      }

      @media (min-width: 992px) {
        &.active {
          > .nav-link {
            &::before {
              content: '';
              height: 2px;
              width: 100%;
              background-color: $menu-item-active-color;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }

    .dropdown {
      &.active {
        > a {
          color: $menu-item-active-color;
          background-color: transparent;
          svg {
            color: $menu-item-active-color;
          }
        }
      }
    }
  }

  .menu-title {
    padding: 12px 24px !important;
    @media (max-width: 991.98px) {
      padding: 12px 16px !important;
    }
  }
}

@include media-breakpoint-up(xl) {
  body[data-layout='horizontal'] {
    .container-fluid,
    .navbar-header {
      max-width: 85%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .topnav {
    .dropdown-item {
      padding: 0.5rem 1.5rem;
      min-width: 180px;
    }

    .dropdown {
      &.mega-dropdown {
        // position: static;
        .mega-dropdown-menu {
          left: 0px;
          right: auto;
        }
      }
      .dropdown-menu {
        margin-top: 0;
        border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

        .arrow-down {
          &::after {
            right: 15px;
            transform: rotate(-135deg) translateY(-50%);
            position: absolute;
          }
        }

        .dropdown {
          .dropdown-menu {
            position: absolute;
            top: 0 !important;
            left: 100%;
            display: none;
          }
        }
      }

      &:hover {
        > .dropdown-menu {
          display: block;
        }
      }
    }

    .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
      display: block;
    }
  }

  .navbar-toggle {
    display: none;
  }
}

.arrow-down {
  display: inline-block;

  &:after {
    border-color: initial;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: '';
    height: 0.4em;
    display: inline-block;
    right: 5px;
    top: 50%;
    margin-left: 10px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    transition: all 0.3s ease-out;
    width: 0.4em;
  }
}

@include media-breakpoint-down(xl) {
  .topnav-menu {
    .navbar-nav {
      li {
        &:last-of-type {
          .dropdown {
            .dropdown-menu {
              right: 100%;
              left: auto;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .navbar-brand-box {
    .logo-dark {
      display: $display-block;
      span.logo-sm {
        display: $display-block;
      }
    }

    .logo-light {
      display: $display-none;
    }
  }

  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;
    .navbar-nav {
      .nav-link {
        padding: 0.75rem 1.1rem;
      }
    }

    .dropdown {
      .dropdown-menu {
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding-left: 20px;
        &.dropdown-mega-menu-xl {
          width: auto;

          .row {
            margin: 0px;
          }
        }
      }

      .dropdown-item {
        position: relative;
        background-color: transparent;

        &.active,
        &:active {
          color: $primary;
        }
      }
    }

    .arrow-down {
      &::after {
        right: 15px;
        position: absolute;
      }
    }
  }
}

// dark
[data-layout-mode='dark'] {
  .topnav {
    .navbar-nav {
      .nav-link {
        &:focus,
        &:hover {
          color: $white;
          svg {
            color: $white;
          }
        }
      }
      .nav-link.active {
        color: $white;
        svg {
          color: $white;
        }
      }
      .dropdown-item {
        &.active,
        &:hover {
          color: $white;
        }
      }

      .dropdown {
        &.active {
          > a {
            color: $white;
            svg {
              color: $white;
            }
          }
        }
      }
    }
  }
}

// Colored Topbar

body[data-layout='horizontal'][data-topbar='colored'] {
  #page-topbar {
    background-color: $primary;
    box-shadow: none;
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }

  .app-search {
    .form-control {
      background-color: rgba($topbar-search-bg, 0.07);
      color: $white;
    }
    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }
  .header-item {
    color: $header-dark-item-color;

    &:hover {
      color: $header-dark-item-color;
    }
  }

  .navbar-header {
    .dropdown .show {
      &.header-item {
        background-color: rgba($white, 0.1);
      }
    }
  }

  .noti-icon {
    i {
      color: $header-dark-item-color;
    }
  }

  @include media-breakpoint-up(lg) {
    .topnav {
      background-color: $primary;
      .navbar-nav {
        .nav-link {
          color: rgba($white, 0.6);

          &:focus,
          &:hover {
            color: rgba($white, 0.9);
          }
        }

        > .dropdown {
          &.active {
            > a {
              color: rgba($white, 0.9) !important;
            }
          }
        }
      }
    }
  }
}
