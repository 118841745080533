//
// _modal.scss
//

.modal-size-350 {
  max-width: 350px;
}

.modal-size-380 {
  max-width: 380px;
}