//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;

  .card-header.bg-light {
    background-color: var(--#{$variable-prefix}light) !important;
  }
  .card-header.bg-dark {
    background-color: var(--#{$variable-prefix}dark) !important;
  }
  &.bg-dark {
    background-color: $dark;
  }
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin-bottom: 0;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

.card-h-100 {
  height: calc(100% - #{$grid-gutter-width});
}

.logs-content__wrapper {
  min-height: 55vh;
}

@mixin card-header-variant($bg) {
  background-color: lighten($bg, 5%) !important;
  border-bottom: none;
}

@each $color, $value in $theme-colors {
  .card-header.bg-#{$color} {
    @include card-header-variant($value);
  }
}

// dark
[data-layout-mode='dark'] {
  .page-content-wrapper {
    background-color: #032d46 !important;
  }
}
