//
// _table.scss
//

@import 'src/styles.scss';

.table {
  th {
    font-weight: $table-th-font-weight;
  }
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}

.table-responsive {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($dark, 0.2);
    border-radius: 10px;
    border: 2px solid $white;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $white;
  }
}

table {
  thead.header-bg {
    background-color: #fafafb;
  }

  td.ellipsis {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.table-borders-rounded-edges {
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    border: 1px solid $border-color-light;
    border-style: none solid solid none;
  }

  thead tr th {
    border-top-style: solid;
  }

  tr {

    td:first-child,
    th:first-child {
      border-left-style: solid;
    }
  }

  th:first-child {
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 5px;
    }

    td:last-child {
      border-bottom-right-radius: 5px;
    }
  }
}

.table-rounded-edges {
  border-collapse: separate;
  border-spacing: 0;

  th {
    border-top: 1px solid $border-color-light;
  }

  th,
  td {
    &:first-child {
      border-left: 1px solid $border-color-light;
    }
  }

  th,
  td {
    &:last-child {
      border-right: 1px solid $border-color-light;
    }
  }

  th:first-child {
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
  }

  tr:last-child {
    td {
      border-bottom: 1px solid $border-color-light;
    }

    td:first-child {
      border-bottom-left-radius: 5px;
    }

    td:last-child {
      border-bottom-right-radius: 5px;
    }
  }
}

.colored-rows-table {
  thead tr {
    background-color: #fafafb;
  }

  tr:nth-child(even) {
    background-color: #fafafb;
  }

  th,
  td {
    border-color: #f3f4f6 !important;
  }
}

// dark
[data-layout-mode='dark'] {
  table {
    thead.header-bg {
      background-color: #033350;
    }
  
    &.colored-rows-table {
      thead tr {
        background-color: #033350;
      }
    }
  
    tr:nth-child(even) {
      background-color: #033350;
    }
  
    th,
    td {
      border-color: inherit !important;
    }
  }
}