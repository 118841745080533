//
// Page-title
//

.page-title-box {
  padding-bottom: $grid-gutter-width;

  .breadcrumb {
    background-color: transparent;
    padding: 0;
  }

  h4 {
    font-size: 18px !important;
  }

  .page-title-right {
    @media (max-width: 575.98px) {
      display: none;
    }
  }
}

.page-subtitle {
  color: #9095a0;
  font-size: 13px;
}

.no-padding-title {
  .page-title-box {
    padding-bottom: 0;
  }
}

[data-layout-mode='dark'] {
  .page-title-box {
    h4 {
      color: #eff0f2;
    }
  }
}
