@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~dropzone/dist/min/dropzone.min.css';
@import '~ngx-lightbox/lightbox.css';
@import '~flatpickr/dist/flatpickr.css';
:root {
  --bs-footer-color: #74788d;
  --bs-footer-shadow: 0 0px 4px rgba(15, 34, 58, 0.12);
  --bs-text-muted: #74788d;
  --bs-input-bg: #fff;
  --bs-input-border-color: #e2e5e8;
  --bs-form-check-input-border: #adb5bd;
  --bs-input-focus-border-color: #b7bfc6;
  --bs-form-select-focus-border-color: #d3d6db;
  --bs-border-color: #eff0f2;
  --bs-light: #fff;
  --bs-white: #fff;
  --bs-dark: #3f474e;
  --bs-topnav-bg: #fff;
}

.accordion {
  --bs-accordion-color: #495057;
}

[data-layout-mode=dark] {
  --bs-gray-100: #03273c;
  --bs-gray-200: #032d46;
  --bs-gray-300: #043a5a;
  --bs-gray-400: #7e93a0;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #eff0f2;
  --bs-gray-700: #e2e5e8;
  --bs-gray-800: #f5f6f8;
  --bs-gray-900: #f8f9fa;
  --bs-body-bg: #03273c;
  --bs-body-bg-rgb: 4, 58, 90;
  --bs-body-color: #7e93a0;
  --bs-body-color-rgb: 226, 229, 232;
  --bs-text-muted: #7e93a0;
  --bs-border-color: #043a5a;
  --bs-light-rgb: 4, 58, 90;
  --bs-light: #043a5a;
  --bs-dark-rgb: 3, 45, 70;
  --bs-dark: #032d46;
  --bs-white: #032d46;
  --bs-heading-color: #eff0f2;
  --bs-footer-color: gray-200;
  --bs-footer-shadow: 0 0px 4px rgba(226, 229, 232, 0.12);
  --bs-input-bg: #033350;
  --bs-input-border-color: #043a5a;
  --bs-form-check-input-border: #043a5a;
  --bs-input-focus-border-color: #043a5a;
  --bs-form-select-focus-border-color: #043d5f;
  --bs-topnav-bg: #03304b;
}
[data-layout-mode=dark] .text-dark {
  --bs-dark-rgb: 245, 246, 248;
}
[data-layout-mode=dark] .card,
[data-layout-mode=dark] .noUi-handle {
  --bs-card-bg: #032d46;
  --bs-card-border-color: #043a5a;
  --bs-card-box-shadow: 0 2px 3px #022032;
  --bs-card-cap-bg: #032d46;
  --bs-card-header-bg: #043a5a;
}
[data-layout-mode=dark] .btn-light {
  --bs-btn-color: #eff0f2;
  --bs-btn-bg: #043a5a;
  --bs-btn-border-color: #043a5a;
}
[data-layout-mode=dark] .btn-outline-light {
  --bs-btn-color: #eff0f2;
  --bs-btn-border-color: #043a5a;
}
[data-layout-mode=dark] .btn-light,
[data-layout-mode=dark] .btn-outline-light {
  --bs-btn-hover-color: #eff0f2;
  --bs-btn-hover-bg: #043a5a;
  --bs-btn-hover-border-color: #043a5a;
  --bs-btn-active-color: #eff0f2;
  --bs-btn-active-bg: #043a5a;
  --bs-btn-active-border-color: #043a5a;
  --bs-btn-focus-shadow-rgb: 4, 58, 90;
}
[data-layout-mode=dark] .btn-dark {
  --bs-btn-bg: #033350;
  --bs-btn-border-color: #033350;
  --bs-btn-hover-bg: #033350;
}
[data-layout-mode=dark] .btn-outline-dark {
  --bs-btn-color: #eff0f2;
  --bs-btn-border-color: #033350;
}
[data-layout-mode=dark] .btn-dark,
[data-layout-mode=dark] .btn-outline-dark {
  --bs-btn-hover-border-color: #033350;
  --bs-btn-active-bg: #033350;
  --bs-btn-active-border-color: #033350;
  --bs-btn-focus-shadow-rgb: 4, 58, 90;
  --bs-btn-hover-bg: #043a5a;
}
[data-layout-mode=dark] .dropdown-menu,
[data-layout-mode=dark] .flatpickr-calendar,
[data-layout-mode=dark] .choices {
  --bs-dropdown-color: #adb5bd;
  --bs-dropdown-bg: #043552;
  --bs-dropdown-border-color: #043a5a;
  --bs-dropdown-box-shadow: 0 2px 3px 3, 45, 70;
  --bs-dropdown-link-color: #adb5bd;
  --bs-dropdown-divider-bg: #043a5a;
  --bs-dropdown-link-hover-bg: #043d5e;
  --bs-dropdown-link-hover-color: #adb5bd;
  --bs-dropdown-link-active-bg: #043a5a;
}
[data-layout-mode=dark] .list-group {
  --bs-list-group-color: #7e93a0;
  --bs-list-group-bg: #032d46;
  --bs-list-group-border-color: #043a5a;
}
[data-layout-mode=dark] .breadcrumb {
  --bs-breadcrumb-item-active-color: #7e93a0;
}
[data-layout-mode=dark] .table,
[data-layout-mode=dark] .gridjs-table {
  --bs-table-border-color: #043a5a;
  --bs-table-color: #7e93a0;
  --bs-table-striped-bg: #03304b;
  --bs-table-striped-color: #7e93a0;
  --bs-table-active-bg: #03324d;
  --bs-table-active-color: #7e93a0;
  --bs-table-hover-bg: #03324d;
  --bs-table-hover-color: #7e93a0;
}
[data-layout-mode=dark] .table-dark {
  --bs-table-bg: #043a5a;
  --bs-table-border-color: #043d5e;
}
[data-layout-mode=dark] .table-light {
  --bs-table-bg: #043552;
  --bs-table-border-color: #043d5e;
  --bs-table-color: #7e93a0;
}
[data-layout-mode=dark] .modal,
[data-layout-mode=dark] .alertify {
  --bs-modal-bg: #032d46;
  --bs-modal-border-color: #043a5a;
  --bs-modal-header-border-color: #043a5a;
  --bs-modal-footer-border-color: #043a5a;
}
[data-layout-mode=dark] .offcanvas,
[data-layout-mode=dark] .offcanvas-lg,
[data-layout-mode=dark] .offcanvas-md,
[data-layout-mode=dark] .offcanvas-sm,
[data-layout-mode=dark] .offcanvas-xl,
[data-layout-mode=dark] .offcanvas-xxl {
  --bs-offcanvas-bg: #032d46;
  --bs-offcanvas-border-color: #043a5a;
}
[data-layout-mode=dark] .progress {
  --bs-progress-bg: #043a5a;
}
[data-layout-mode=dark] .accordion {
  --bs-accordion-border-color: #043a5a;
  --bs-accordion-color: #7e93a0;
  --bs-accordion-btn-color: #eff0f2;
  --bs-accordion-active-bg: rgba(0, 120, 212, 0.2);
}
[data-layout-mode=dark] .nav-tabs {
  --bs-nav-tabs-link-active-bg: #032d46;
  --bs-nav-tabs-link-active-border-color: #043a5a;
  --bs-nav-tabs-border-color: #043a5a;
  --bs-nav-tabs-link-hover-border-color: #043a5a;
  --bs-nav-tabs-link-active-color: #0078d4;
}
[data-layout-mode=dark] .popover {
  --bs-popover-bg: #043a5a;
  --bs-popover-body-color: #eff0f2;
  --bs-popover-border-color: #05476e;
  --bs-popover-header-bg: #043a5a;
  --bs-popover-header-color: #eff0f2;
}
[data-layout-mode=dark] .pagination,
[data-layout-mode=dark] .gridjs-pagination {
  --bs-pagination-bg: #032d46;
  --bs-pagination-border-color: #043a5a;
  --bs-pagination-color: #adb5bd;
  --bs-pagination-hover-bg: #033350;
  --bs-pagination-hover-color: #0078d4;
  --bs-pagination-hover-border-color: #043a5a;
  --bs-pagination-disabled-bg: #033350;
  --bs-pagination-disabled-color: #adb5bd;
  --bs-pagination-disabled-border-color: #043a5a;
}
[data-layout-mode=dark] .alert {
  --bs-alert-dark: #043a5a;
}

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 255px;
  z-index: 1002;
  background-color: #ffffff;
  box-shadow: 0 2px 3px rgba(52, 58, 64, 0.06);
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(20px / 2) 0 0;
}
.navbar-header .dropdown .header-item.show {
  color: #0078d4;
}
.navbar-header .navbar-brand-box {
  display: none;
}

body:not([data-sidebar-size=sm]) .navbar-header .vertical-menu-btn {
  display: none;
}
body:not([data-sidebar-size=sm]) .app-search {
  margin-left: 10px;
}

.navbar-brand-box {
  padding: 0 1.5rem;
  width: 255px;
  position: fixed;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 2px 3px rgba(52, 58, 64, 0.06);
}

.logo {
  line-height: 70px;
}
.logo .logo-sm {
  display: none;
}

.logo-dark {
  display: block;
}

.logo-light {
  display: none;
}

/* Search */
.app-search {
  padding: calc(32px / 2) 0;
}
.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 5px;
}
.app-search span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 13px;
  top: 0;
  color: #74788d;
}

.megamenu-list li {
  position: relative;
  padding: 5px 0px;
}
.megamenu-list li a {
  color: #495057;
}

@media (max-width: 991.98px) {
  .navbar-brand-box {
    width: auto;
  }
  #page-topbar {
    left: 0;
  }
  #page-topbar .navbar-header .navbar-brand-box {
    display: inline-block;
    position: relative;
  }
  #page-topbar .navbar-header .vertical-menu-btn {
    display: inline-block;
  }
  .logo span.logo-lg {
    display: none;
  }
  .logo span.logo-sm {
    display: inline-block;
  }
}
.page-content {
  padding: calc(70px + 20px) calc(20px / 2) 60px calc(20px / 2);
}

.header-item {
  height: 70px;
  box-shadow: none !important;
  color: #555b6d;
  border: 0;
  border-radius: 0px;
}
.header-item:hover {
  color: #555b6d;
}

.header-profile-user {
  height: 36px;
  width: 36px;
  border: 1px solid #e9ebed;
  padding: 3px;
  object-fit: cover;
}

.user .user-item-desc {
  line-height: 1.2;
}
.user .user-name {
  font-size: 14.4px;
  font-weight: 600;
  display: block;
  color: #495057;
}
.user .user-sub-title {
  color: var(--bs-text-muted);
  font-size: 11px;
  font-weight: 600;
}

.noti-icon {
  position: relative;
}
.noti-icon i {
  font-size: 20px;
  color: #555b6d;
}
.noti-icon .noti-dot {
  position: absolute;
  top: 25px;
  right: 15px;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  box-shadow: 0 0 0 3px #ffffff;
}

.notification-item .d-flex {
  padding: 0.75rem 1rem;
}
.notification-item .d-flex:hover {
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #74788d;
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-icon-item:hover {
  border-color: #f5f6f8;
}

.fullscreen-enable [data-toggle=fullscreen] .uil-minus-path::before {
  content: "\eb8d";
}

body[data-topbar=dark] #page-topbar,
body[data-topbar=dark] .navbar-brand-box {
  background-color: #03314d;
}
body[data-topbar=dark] .navbar-header .dropdown.show .header-item {
  background-color: rgba(255, 255, 255, 0.05);
}
body[data-topbar=dark] .header-item {
  color: #e9ecef;
}
body[data-topbar=dark] .header-item:hover {
  color: #e9ecef;
}
body[data-topbar=dark] .header-profile-user {
  border-color: rgba(255, 255, 255, 0.25);
}
body[data-topbar=dark] .user-name {
  color: #e9ecef;
}
body[data-topbar=dark] .user-sub-title {
  color: rgba(255, 255, 255, 0.5);
}
body[data-topbar=dark] .noti-icon i {
  color: #e9ecef;
}
body[data-topbar=dark] .noti-icon .noti-dot {
  box-shadow: 0 0 0 3px #03314d;
}
body[data-topbar=dark] .logo-dark {
  display: none;
}
body[data-topbar=dark] .logo-light {
  display: block;
}
body[data-topbar=dark] .app-search .form-control {
  background-color: #043d61;
  color: #fff;
}
body[data-topbar=dark] .app-search span,
body[data-topbar=dark] .app-search input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

body[data-sidebar=dark] .navbar-brand-box {
  background: #032d46;
}
body[data-sidebar=dark] .navbar-brand-box .logo-dark {
  display: none;
}
body[data-sidebar=dark] .navbar-brand-box .logo-light {
  display: block;
}

@media (max-width: 600px) {
  .navbar-header .dropdown {
    position: static;
  }
  .navbar-header .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
    transform: translate(0, 70px) !important;
  }
}
@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}
@media (max-width: 374.99px) {
  .navbar-brand-box {
    padding: 0 1rem;
  }
  .language-switch {
    display: none !important;
  }
}
.page-title-box {
  padding-bottom: 20px;
}
.page-title-box .breadcrumb {
  background-color: transparent;
  padding: 0;
}
.page-title-box h4 {
  font-size: 18px !important;
}
@media (max-width: 575.98px) {
  .page-title-box .page-title-right {
    display: none;
  }
}

.page-subtitle {
  color: #9095a0;
  font-size: 13px;
}

.no-padding-title .page-title-box {
  padding-bottom: 0;
}

[data-layout-mode=dark] .page-title-box h4 {
  color: #eff0f2;
}

.footer {
  bottom: 0;
  padding: 20px calc(20px / 2);
  position: absolute;
  right: 0;
  color: var(--bs-footer-color);
  left: 250px;
  height: 60px;
  box-shadow: var(--bs-footer-shadow);
}

@media (max-width: 992px) {
  .footer {
    left: 0;
  }
}
body[data-sidebar-size=sm] .footer {
  left: 70px;
}

body[data-layout=horizontal] .footer {
  left: 0 !important;
}

.right-bar {
  background-color: var(--bs-white);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: 280px;
  z-index: 9999;
  float: right !important;
  right: -290px;
  top: 0;
  bottom: 0;
}
.right-bar .right-bar-toggle-close {
  background-color: #444c54;
  height: 24px;
  width: 24px;
  line-height: 24px;
  color: #f5f6f8;
  text-align: center;
  border-radius: 50%;
}
.right-bar .right-bar-toggle-close:hover {
  background-color: #4b545c;
}

.rightbar-overlay {
  background-color: rgba(52, 58, 64, 0.55);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}

.right-bar-enabled .right-bar {
  right: 0;
}
.right-bar-enabled .rightbar-overlay {
  display: block;
}

@media (max-width: 575.98px) {
  .right-bar {
    overflow: auto;
  }
  .right-bar .slimscroll-menu {
    height: auto !important;
  }
}
[data-layout-mode=dark] .rightbar-title {
  background-color: var(--bs-input-bg) !important;
}
[data-layout-mode=dark] .form-check-inline .form-check-input {
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.metismenu {
  margin: 0;
}
.metismenu li {
  display: block;
  width: 100%;
}
.metismenu .mm-collapse {
  display: none;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.metismenu .mm-collapse.mm-show {
  display: block;
}
.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height, visibility;
}

.vertical-menu {
  width: 255px;
  z-index: 1005;
  background: #ffffff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  border-right: 1px solid #e9ebef;
}
.vertical-menu .vertical-menu-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.main-content {
  margin-left: 255px;
  overflow: hidden;
}
.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
}

.sidebar-menu-scroll {
  height: calc(100% - 70px);
  margin-top: 70px;
}

#sidebar-menu {
  padding: 10px 0 30px 0;
}
#sidebar-menu .mm-active > .has-arrow:after {
  transform: rotate(-180deg);
}
#sidebar-menu .has-arrow:after {
  content: "\f0140";
  font-family: "Material Design Icons";
  display: block;
  transition: transform 0.2s;
  font-size: 1rem;
  position: absolute;
  right: 15px;
  top: 9px;
}
#sidebar-menu .has-arrow + ul .has-arrow:after {
  right: 25px;
  top: 7px;
}
#sidebar-menu .metismenu .menu-title::after {
  display: none;
}
#sidebar-menu .metismenu .menu-item {
  margin-left: 3px;
}
#sidebar-menu ul li a {
  display: flex;
  padding: 0.5rem 1rem;
  color: #7b8190;
  position: relative;
  font-weight: 400;
  transition: all 0.4s;
  margin: 0 10px;
  border-radius: 3px;
  font-size: 0.8rem;
  font-family: "Inter", sans-serif;
  align-items: center;
}
#sidebar-menu ul li a .nav-icon svg {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  color: #7b8190;
  transition: all 0.3s;
  top: -1px;
}
#sidebar-menu ul li a:hover {
  color: #383c40;
}
#sidebar-menu ul li a:hover .nav-icon {
  color: #383c40;
}
#sidebar-menu ul li .badge {
  margin-left: auto;
}
#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}
#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.5rem 1.5rem 0.5rem 3.6rem;
  font-weight: 400;
  color: #7b8190;
  margin: 0;
  background-color: transparent;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  margin-right: 10px;
}
#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #383c40;
}
#sidebar-menu ul li ul.sub-menu li a:hover:before {
  border-color: #0078d4;
  background-color: #0078d4;
}
#sidebar-menu ul li ul.sub-menu li a:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: 0 0;
  border: 1px solid #7b8190;
  position: absolute;
  left: 35px;
  top: 16px;
  transition: all 0.4s ease-in-out;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a:before {
  left: 55px;
  top: 15px;
}
#sidebar-menu ul li.mm-active {
  color: #038edc;
}
#sidebar-menu ul li.mm-active > a {
  background-color: #f2f3fe;
  color: #038edc;
}
#sidebar-menu ul li.mm-active > a .nav-icon {
  color: #038edc !important;
}
#sidebar-menu ul li.mm-active .mm-show .mm-active > a {
  color: #383c40 !important;
}
#sidebar-menu ul li.mm-active .mm-show .mm-active > a:before {
  border-color: #0078d4 !important;
  background-color: #0078d4 !important;
}

.menu-title {
  padding: 12px 25px !important;
  letter-spacing: 0.08em;
  pointer-events: none;
  cursor: default;
  font-size: 10px;
  text-transform: uppercase;
  color: #7b8190;
  font-weight: 600;
  opacity: 0.6;
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
    top: 70px;
  }
  .vertical-menu .sidebar-menu-scroll {
    height: 100%;
    margin-top: 0;
  }
  .vertical-menu .navbar-brand-box,
  .vertical-menu .vertical-menu-btn {
    display: none;
  }
  .main-content {
    margin-left: 0 !important;
  }
  body.sidebar-enable .vertical-menu {
    display: block;
  }
  body #page-topbar,
  body[data-sidebar-size=sm] #page-topbar {
    left: 0 !important;
  }
}
@media (min-width: 769px) {
  body[data-sidebar-size=sm] {
    min-height: 1850px;
  }
}
body[data-sidebar-size=sm] .main-content {
  margin-left: 70px;
}
body[data-sidebar-size=sm] .navbar-brand-box {
  width: 70px !important;
}
body[data-sidebar-size=sm] .logo span.logo-lg {
  display: none;
}
body[data-sidebar-size=sm] .logo span.logo-sm {
  display: block;
}
body[data-sidebar-size=sm] #page-topbar {
  left: 70px;
}
body[data-sidebar-size=sm] .vertical-menu {
  position: absolute;
  width: 70px !important;
  z-index: 1001;
}
body[data-sidebar-size=sm] .vertical-menu .simplebar-mask,
body[data-sidebar-size=sm] .vertical-menu .simplebar-content-wrapper {
  overflow: visible !important;
}
body[data-sidebar-size=sm] .vertical-menu .simplebar-scrollbar,
body[data-sidebar-size=sm] .vertical-menu .vertical-menu-btn {
  display: none !important;
}
body[data-sidebar-size=sm] .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu .menu-title,
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu .badge {
  display: none !important;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu .has-arrow:after {
  transform: rotate(-90deg);
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a {
  padding: 15px 13px;
  transition: none;
  display: block;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a:hover, body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a:active, body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a:focus {
  color: #383c40;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a i {
  font-size: 1.3rem;
  text-align: center;
  min-width: 50px;
  padding-bottom: 0;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a span {
  display: none;
  padding-left: 25px;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a.has-arrow:after {
  display: none;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a .nav-icon {
  top: -2px;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > a {
  position: relative;
  width: calc(180px + 70px);
  color: #0078d4;
  background-color: #f2f3fe;
  transition: none;
  box-shadow: inset 0 3px 10px 0 rgba(154, 161, 171, 0.2);
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > a .nav-icon {
  color: #0078d4;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > a span {
  display: inline;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
  box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > ul ul {
  box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
  color: #7b8190;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #383c40;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: #ffffff;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul ul li:hover > ul {
  display: block;
  left: 190px;
  height: auto !important;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul ul li > a span.pull-right {
  position: absolute;
  right: 20px;
  top: 12px;
  transform: rotate(270deg);
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul ul li.active a {
  color: #f8f9fa;
}
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu ul li ul.sub-menu li a:before {
  display: none;
}

body[data-sidebar=dark] .vertical-menu {
  background: #032d46;
  border-color: #032d46;
}
body[data-sidebar=dark] .vertical-menu .vertical-menu-btn {
  color: #7e93a0;
}
body[data-sidebar=dark] #sidebar-menu ul li a {
  color: #7e93a0;
}
body[data-sidebar=dark] #sidebar-menu ul li a .nav-icon svg {
  color: #637c8c;
}
body[data-sidebar=dark] #sidebar-menu ul li a:hover svg {
  color: #ffffff;
}
body[data-sidebar=dark] #sidebar-menu ul li a:hover .nav-icon svg {
  color: #ffffff;
}
body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a {
  color: #718896;
  background-color: transparent;
}
body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:before {
  border-color: #718896;
}
body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #ffffff;
}
body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:hover:before {
  background-color: #ffffff;
  border-color: #ffffff;
}
body[data-sidebar=dark] #sidebar-menu ul li.mm-active {
  color: #ffffff !important;
}
body[data-sidebar=dark] #sidebar-menu ul li.mm-active > a {
  background-color: #043655;
  color: #ffffff !important;
}
body[data-sidebar=dark] #sidebar-menu ul li.mm-active > a .nav-icon svg {
  color: #ffffff !important;
}
body[data-sidebar=dark] #sidebar-menu ul li.mm-active .mm-show .mm-active > a {
  color: #ffffff !important;
}
body[data-sidebar=dark] #sidebar-menu ul li.mm-active .mm-show .mm-active > a:before {
  border-color: #ffffff !important;
  background-color: #ffffff !important;
}
body[data-sidebar=dark][data-sidebar-size=sm] {
  min-height: 1300px;
}
body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > a {
  background: #043655;
  color: #ffffff;
}
body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > a .nav-icon svg {
  color: #ffffff;
}
body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  color: #718896;
}
body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover svg {
  color: #ffffff;
}
body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul ul {
  background-color: #032d46;
}
body[data-sidebar=dark] .menu-title {
  color: #7e93a0;
}

body[data-layout=horizontal] .main-content {
  margin-left: 0 !important;
}

body[data-sidebar-size=md] .navbar-brand-box {
  width: 160px;
}
@media (max-width: 991.98px) {
  body[data-sidebar-size=md] .navbar-brand-box {
    width: auto;
  }
}
body[data-sidebar-size=md] .vertical-menu {
  width: 160px;
  text-align: center;
}
body[data-sidebar-size=md] .vertical-menu .has-arrow:after,
body[data-sidebar-size=md] .vertical-menu .badge {
  display: none !important;
}
body[data-sidebar-size=md] .vertical-menu .vertical-menu-btn {
  display: none;
}
body[data-sidebar-size=md] .main-content {
  margin-left: 160px;
}
body[data-sidebar-size=md] #page-topbar,
body[data-sidebar-size=md] .footer {
  left: 160px;
}
@media (max-width: 991.98px) {
  body[data-sidebar-size=md] #page-topbar,
  body[data-sidebar-size=md] .footer {
    left: 0;
  }
}
body[data-sidebar-size=md] #sidebar-menu ul li.menu-title {
  background-color: #cccccc;
}
body[data-sidebar-size=md] #sidebar-menu ul li .menu-item {
  margin-left: 0px;
}
body[data-sidebar-size=md] #sidebar-menu ul li a {
  font-size: 0.8rem;
  padding: 0.75rem 1.1rem;
  display: block;
}
body[data-sidebar-size=md] #sidebar-menu ul li a .nav-icon {
  width: 20px;
  height: 20px;
  display: block !important;
  margin: 0 auto 5px auto !important;
  padding-bottom: 0.2em;
  top: -2px;
}
body[data-sidebar-size=md] #sidebar-menu ul li ul.sub-menu li a {
  padding-left: 1.5rem;
}
body[data-sidebar-size=md] #sidebar-menu ul li ul.sub-menu li a:before {
  display: none;
}
body[data-sidebar-size=md][data-sidebar=dark] #sidebar-menu ul li.menu-title {
  background-color: #033350;
}
body[data-sidebar-size=md]:not(.vertical-collpsed) .navbar-header .vertical-menu-btn {
  display: block;
}
body[data-sidebar-size=md][data-sidebar-size=sm] .main-content {
  margin-left: 70px;
}
body[data-sidebar-size=md][data-sidebar-size=sm] .vertical-menu #sidebar-menu {
  text-align: left;
}
body[data-sidebar-size=md][data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li > a .nav-icon {
  display: inline-block;
}
body[data-sidebar-size=md][data-sidebar-size=sm] #page-topbar,
body[data-sidebar-size=md][data-sidebar-size=sm] .footer {
  left: 70px;
}

body[data-sidebar=brand] .vertical-menu {
  background-color: #0078d4;
  border-color: #0078d4;
}
body[data-sidebar=brand] .vertical-menu::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.075;
}
body[data-sidebar=brand] .vertical-menu .vertical-menu-btn {
  color: rgba(255, 255, 255, 0.8);
}
body[data-sidebar=brand] .navbar-brand-box {
  background-color: #0078d4;
}
body[data-sidebar=brand] .navbar-brand-box .logo-dark {
  display: none;
}
body[data-sidebar=brand] .navbar-brand-box .logo-light {
  display: block;
}
body[data-sidebar=brand] #sidebar-menu ul li.menu-title {
  color: rgba(255, 255, 255, 0.7);
}
body[data-sidebar=brand] #sidebar-menu ul li a {
  color: rgba(255, 255, 255, 0.7);
}
body[data-sidebar=brand] #sidebar-menu ul li a .nav-icon svg {
  color: rgba(255, 255, 255, 0.7);
}
body[data-sidebar=brand] #sidebar-menu ul li a:hover svg {
  color: #fff;
}
body[data-sidebar=brand] #sidebar-menu ul li a:hover .nav-icon svg {
  color: #fff;
}
body[data-sidebar=brand] #sidebar-menu ul li ul.sub-menu li a {
  color: rgba(255, 255, 255, 0.6);
}
body[data-sidebar=brand] #sidebar-menu ul li ul.sub-menu li a:before {
  border-color: rgba(255, 255, 255, 0.6);
}
body[data-sidebar=brand] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #fff;
}
body[data-sidebar=brand] #sidebar-menu ul li ul.sub-menu li a:hover:before {
  background-color: #fff;
  border-color: #fff;
}
body[data-sidebar=brand] #sidebar-menu ul li .badge {
  background-color: #fff;
}
body[data-sidebar=brand] #sidebar-menu ul li.mm-active {
  color: #fff !important;
}
body[data-sidebar=brand] #sidebar-menu ul li.mm-active > a {
  background: none;
  color: #fff !important;
}
body[data-sidebar=brand] #sidebar-menu ul li.mm-active > a .nav-icon {
  color: #fff !important;
}
body[data-sidebar=brand] #sidebar-menu ul li.mm-active .mm-show .mm-active > a {
  color: #fff !important;
}
body[data-sidebar=brand] #sidebar-menu ul li.mm-active .mm-show .mm-active > a:before {
  border-color: #fff !important;
  background-color: #fff !important;
}
body[data-sidebar=brand][data-topbar=light] .navbar-brand-box {
  background-color: #0078d4;
}
body[data-sidebar=brand][data-topbar=light] .navbar-brand-box .logo-dark {
  display: none;
}
body[data-sidebar=brand][data-topbar=light] .navbar-brand-box .logo-light {
  display: block;
}
body[data-sidebar=brand][data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > a {
  background-color: #007ede;
  color: #fff;
}
body[data-sidebar=brand][data-sidebar-size=sm] .vertical-menu #sidebar-menu > ul > li:hover > a .nav-icon {
  color: #fff;
}

body[data-layout=horizontal] #page-topbar {
  left: 0;
  box-shadow: none;
}
body[data-layout=horizontal] .navbar-brand-box {
  display: block;
  width: auto;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  position: relative;
}
@media (min-width: 992px) {
  body[data-layout=horizontal] .navbar-brand-box {
    padding-left: 0;
    margin-right: 10px;
  }
}
body[data-layout=horizontal] .page-content {
  margin-top: 70px;
  padding: calc(55px + 20px) calc(20px / 2) 60px calc(20px / 2);
}
@media (max-width: 991.98px) {
  body[data-layout=horizontal] .page-content {
    padding-top: 20px;
  }
}
@media (min-width: 992px) {
  body[data-layout=horizontal] .navbar-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
body[data-layout=horizontal][data-sidebar-size=sm] .navbar-brand-box {
  width: auto !important;
}

.topnav {
  background: var(--bs-topnav-bg) !important;
  padding: 0 calc(20px / 2);
  z-index: 100;
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 1px solid var(--bs-border-color);
}
@media (min-width: 992px) {
  .topnav {
    background: #fff;
  }
}
.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}
.topnav .navbar-nav .nav-link {
  font-size: 14.4px;
  position: relative;
  padding: 1rem 1.3rem;
  color: #7b8190;
  font-weight: 400;
}
.topnav .navbar-nav .nav-link i {
  font-size: 15px;
}
.topnav .navbar-nav .nav-link svg {
  height: 16px;
  width: 16px;
  color: #7b8190;
  margin-right: 7px;
  margin-top: -1px;
}
.topnav .navbar-nav .nav-link:focus, .topnav .navbar-nav .nav-link:hover {
  color: #038edc;
  background-color: transparent;
}
.topnav .navbar-nav .nav-link:focus svg, .topnav .navbar-nav .nav-link:hover svg {
  color: #038edc;
}
.topnav .navbar-nav .dropdown-item {
  color: #7b8190;
  background: transparent;
}
.topnav .navbar-nav .dropdown-item.active, .topnav .navbar-nav .dropdown-item:hover {
  color: #038edc;
}
.topnav .navbar-nav .nav-item .nav-link.active {
  color: #038edc;
}
.topnav .navbar-nav .nav-item .nav-link.active svg {
  color: #038edc;
}
@media (min-width: 992px) {
  .topnav .navbar-nav .nav-item.active > .nav-link::before {
    content: "";
    height: 2px;
    width: 100%;
    background-color: #038edc;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.topnav .navbar-nav .dropdown.active > a {
  color: #038edc;
  background-color: transparent;
}
.topnav .navbar-nav .dropdown.active > a svg {
  color: #038edc;
}
.topnav .menu-title {
  padding: 12px 24px !important;
}
@media (max-width: 991.98px) {
  .topnav .menu-title {
    padding: 12px 16px !important;
  }
}

@media (min-width: 1200px) {
  body[data-layout=horizontal] .container-fluid,
  body[data-layout=horizontal] .navbar-header {
    max-width: 85%;
  }
}
@media (min-width: 992px) {
  .topnav .dropdown-item {
    padding: 0.5rem 1.5rem;
    min-width: 180px;
  }
  .topnav .dropdown.mega-dropdown .mega-dropdown-menu {
    left: 0px;
    right: auto;
  }
  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  .topnav .dropdown .dropdown-menu .arrow-down::after {
    right: 15px;
    transform: rotate(-135deg) translateY(-50%);
    position: absolute;
  }
  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    position: absolute;
    top: 0 !important;
    left: 100%;
    display: none;
  }
  .topnav .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-toggle {
    display: none;
  }
}
.arrow-down {
  display: inline-block;
}
.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  transition: all 0.3s ease-out;
  width: 0.4em;
}

@media (max-width: 1199.98px) {
  .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
    right: 100%;
    left: auto;
  }
}
@media (max-width: 991.98px) {
  .navbar-brand-box .logo-dark {
    display: block;
  }
  .navbar-brand-box .logo-dark span.logo-sm {
    display: block;
  }
  .navbar-brand-box .logo-light {
    display: none;
  }
  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;
  }
  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.1rem;
  }
  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 20px;
  }
  .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl {
    width: auto;
  }
  .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl .row {
    margin: 0px;
  }
  .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }
  .topnav .dropdown .dropdown-item.active, .topnav .dropdown .dropdown-item:active {
    color: #0078d4;
  }
  .topnav .arrow-down::after {
    right: 15px;
    position: absolute;
  }
}
[data-layout-mode=dark] .topnav .navbar-nav .nav-link:focus, [data-layout-mode=dark] .topnav .navbar-nav .nav-link:hover {
  color: #fff;
}
[data-layout-mode=dark] .topnav .navbar-nav .nav-link:focus svg, [data-layout-mode=dark] .topnav .navbar-nav .nav-link:hover svg {
  color: #fff;
}
[data-layout-mode=dark] .topnav .navbar-nav .nav-link.active {
  color: #fff;
}
[data-layout-mode=dark] .topnav .navbar-nav .nav-link.active svg {
  color: #fff;
}
[data-layout-mode=dark] .topnav .navbar-nav .dropdown-item.active, [data-layout-mode=dark] .topnav .navbar-nav .dropdown-item:hover {
  color: #fff;
}
[data-layout-mode=dark] .topnav .navbar-nav .dropdown.active > a {
  color: #fff;
}
[data-layout-mode=dark] .topnav .navbar-nav .dropdown.active > a svg {
  color: #fff;
}

body[data-layout=horizontal][data-topbar=colored] #page-topbar {
  background-color: #0078d4;
  box-shadow: none;
}
body[data-layout=horizontal][data-topbar=colored] .logo-dark {
  display: none;
}
body[data-layout=horizontal][data-topbar=colored] .logo-light {
  display: block;
}
body[data-layout=horizontal][data-topbar=colored] .app-search .form-control {
  background-color: rgba(0, 0, 0, 0.07);
  color: #fff;
}
body[data-layout=horizontal][data-topbar=colored] .app-search span,
body[data-layout=horizontal][data-topbar=colored] .app-search input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
body[data-layout=horizontal][data-topbar=colored] .header-item {
  color: #e9ecef;
}
body[data-layout=horizontal][data-topbar=colored] .header-item:hover {
  color: #e9ecef;
}
body[data-layout=horizontal][data-topbar=colored] .navbar-header .dropdown .show.header-item {
  background-color: rgba(255, 255, 255, 0.1);
}
body[data-layout=horizontal][data-topbar=colored] .noti-icon i {
  color: #e9ecef;
}
@media (min-width: 992px) {
  body[data-layout=horizontal][data-topbar=colored] .topnav {
    background-color: #0078d4;
  }
  body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:focus, body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.9);
  }
  body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav > .dropdown.active > a {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}

body[data-layout-size=boxed] {
  background-color: #eaedf2;
}
body[data-layout-size=boxed] #layout-wrapper {
  background-color: #f7f8fa;
  max-width: 1400px;
  margin: 0 auto;
  box-shadow: 0 2px 3px rgba(52, 58, 64, 0.06);
}
body[data-layout-size=boxed] #page-topbar {
  max-width: calc(1400px - 255px);
  margin: 0 auto;
}
body[data-layout-size=boxed] .footer {
  margin: 0 auto;
  max-width: calc(1400px - 255px);
}
body[data-layout-size=boxed][data-sidebar-size=sm] #page-topbar {
  max-width: calc(1400px - 70px);
}
body[data-layout-size=boxed][data-sidebar-size=sm] .footer {
  max-width: calc(1400px - 70px);
}
@media (min-width: 769px) {
  body[data-layout-size=boxed][data-sidebar-size=sm] .main-content {
    min-height: 1850px;
  }
}
body[data-layout-size=boxed][data-sidebar-size=md] #page-topbar {
  max-width: calc(1400px - 160px);
}
body[data-layout-size=boxed][data-sidebar-size=md] .footer {
  max-width: calc(1400px - 160px);
}

body[data-layout=horizontal][data-layout-size=boxed] #page-topbar,
body[data-layout=horizontal][data-layout-size=boxed] #layout-wrapper,
body[data-layout=horizontal][data-layout-size=boxed] .footer {
  max-width: 100%;
}
body[data-layout=horizontal][data-layout-size=boxed] .container-fluid,
body[data-layout=horizontal][data-layout-size=boxed] .navbar-header {
  max-width: 1400px;
}

@media (min-width: 992px) {
  body[data-layout-scrollable=true] #page-topbar,
  body[data-layout-scrollable=true] .vertical-menu {
    position: absolute;
  }
  body[data-layout-scrollable=true] .navbar-brand-box {
    position: absolute;
  }
}
@media (min-width: 992px) {
  body[data-layout-scrollable=true][data-layout=horizontal] #page-topbar {
    position: absolute;
  }
  body[data-layout-scrollable=true][data-layout=horizontal] .navbar-brand-box {
    position: relative;
  }
}

.main-page-wrapper.warning-enabled {
  margin-top: 56px;
}
.main-page-wrapper.warning-enabled #page-topbar {
  top: 56px;
}
.main-page-wrapper.warning-enabled .vertical-menu {
  top: 56px;
}

.persona-xs {
  height: 1rem;
  width: 1rem;
}

.persona-sm {
  height: 2rem;
  width: 2rem;
}

.persona {
  height: 3rem;
  width: 3rem;
}

.persona-md {
  height: 4rem;
  width: 4rem;
}

.persona-lg {
  height: 5rem;
  width: 5rem;
}

.persona-xl {
  height: 6rem;
  width: 6rem;
}

.persona-title {
  align-items: center;
  background-color: #0078d4;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.persona-group {
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
}
.persona-group .persona-group-item {
  margin-left: -8px;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.2s;
}
.persona-group .persona-group-item:hover {
  position: relative;
  transform: translateY(-2px);
}

.alert-top-border {
  background-color: var(--bs-card-bg);
  border-color: var(--bs-light);
  border-top: 2px solid #f5f6f8;
}

.alert-outline {
  background-color: var(--bs-card-bg);
}

.alert-light {
  color: var(--bs-text-muted);
}

.alert-top-border.alert-primary {
  border-top-color: #0078d4;
  color: #0078d4;
}

.alert-outline.alert-primary {
  border: 1px solid rgba(0, 120, 212, 0.8);
  color: #0078d4;
}

.alert-top-border.alert-secondary {
  border-top-color: #74788d;
  color: #74788d;
}

.alert-outline.alert-secondary {
  border: 1px solid rgba(116, 120, 141, 0.8);
  color: #74788d;
}

.alert-top-border.alert-success {
  border-top-color: #51d28c;
  color: #51d28c;
}

.alert-outline.alert-success {
  border: 1px solid rgba(81, 210, 140, 0.8);
  color: #51d28c;
}

.alert-top-border.alert-info {
  border-top-color: #5fd0f3;
  color: #5fd0f3;
}

.alert-outline.alert-info {
  border: 1px solid rgba(95, 208, 243, 0.8);
  color: #5fd0f3;
}

.alert-top-border.alert-warning {
  border-top-color: #f7cc53;
  color: #f7cc53;
}

.alert-outline.alert-warning {
  border: 1px solid rgba(247, 204, 83, 0.8);
  color: #f7cc53;
}

.alert-top-border.alert-danger {
  border-top-color: #f34e4e;
  color: #f34e4e;
}

.alert-outline.alert-danger {
  border: 1px solid rgba(243, 78, 78, 0.8);
  color: #f34e4e;
}

.alert-top-border.alert-pink {
  border-top-color: #e83e8c;
  color: #e83e8c;
}

.alert-outline.alert-pink {
  border: 1px solid rgba(232, 62, 140, 0.8);
  color: #e83e8c;
}

.alert-top-border.alert-light {
  border-top-color: #f5f6f8;
  color: #f5f6f8;
}

.alert-outline.alert-light {
  border: 1px solid rgba(245, 246, 248, 0.8);
  color: #f5f6f8;
}

.alert-top-border.alert-dark {
  border-top-color: #343a40;
  color: #343a40;
}

.alert-outline.alert-dark {
  border: 1px solid rgba(52, 58, 64, 0.8);
  color: #343a40;
}

.alert-top-border.alert-purple {
  border-top-color: #564ab1;
  color: #564ab1;
}

.alert-outline.alert-purple {
  border: 1px solid rgba(86, 74, 177, 0.8);
  color: #564ab1;
}

.alert-top-border.alert-light,
.alert-outline.alert-light {
  color: var(--bs-text-muted);
}
.alert-top-border.alert-dark,
.alert-outline.alert-dark {
  color: var(--bs-dark);
  border-top-color: var(--bs-border-color);
}
.alert-top-border.alert-outline,
.alert-outline.alert-outline {
  border-color: var(--bs-border-color);
}

ngb-alert {
  width: fit-content;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 105;
}

.accordion-header .accordion-button .accordion-button {
  padding: 0;
}
.accordion-header .accordion-button .accordion-button:after {
  display: none;
}

.accordion-button .custom-accordion .accordion-list {
  display: flex;
  border-radius: 7px;
  background-color: #eff0f2;
  padding: 12px 20px;
  color: #495057;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
}
.accordion-button .custom-accordion .accordion-list.collapsed i.accor-plus-icon:before {
  content: "\f0415";
}
.accordion-button .custom-accordion .accordion-list .accor-plus-icon {
  display: inline-block;
  font-size: 16px;
  height: 24px;
  width: 24px;
  line-height: 22px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
}
.accordion-button .custom-accordion a.collapsed i.accor-down-icon:before {
  content: "\f0140";
}
.accordion-button .custom-accordion .card-body {
  color: var(--bs-text-muted);
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: #000;
}

.bg-white {
  background-color: white;
}

.social-list-item {
  height: 2.4rem;
  width: 2.4rem;
  line-height: calc(2.4rem - 2px);
  display: block;
  border: 1px solid #fff;
  border-radius: 50%;
  color: var(--bs-text-muted);
  text-align: center;
  transition: all 0.4s;
}
.social-list-item:hover {
  color: #343a40;
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

.alert-dismissible .btn-close {
  font-size: 10px;
  padding: 1.05rem 1.25rem;
}

.loading-spinner {
  cursor: progress;
}

[data-layout-mode=dark] form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] form ::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] form :-ms-input-placeholder {
  /* IE 10+ */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] form :-moz-placeholder {
  /* Firefox 18- */
  color: var(--bs-body-color);
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
}

#status {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin: -20px 0 0 -20px;
}

.spinner .spin-icon {
  font-size: 48px;
  color: #0078d4;
  position: relative;
  display: inline-block;
  animation: spin 1.6s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.modal-size-350 {
  max-width: 350px;
}

.modal-size-380 {
  max-width: 380px;
}

[type=tel]::placeholder,
[type=url]::placeholder,
[type=email]::placeholder,
[type=number]::placeholder {
  text-align: left;
}

.form-check {
  position: relative;
  text-align: left;
}

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: 1.5em;
}
.form-check-right .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}
.form-check-right .form-check-label {
  display: block;
}

.form-checkbox-outline .form-check-input {
  border-width: 2px;
  background-color: #fff;
}
.form-checkbox-outline .form-check-input:active {
  filter: none;
}
.form-checkbox-outline .form-check-input:checked {
  background-color: #fff !important;
}
.form-checkbox-outline .form-check-input:checked[type=checkbox] {
  background-image: none;
}
.form-checkbox-outline .form-check-input:checked:after {
  position: absolute;
  content: "\f012c";
  font-family: "Material Design Icons";
  top: -4px !important;
  left: 1px;
  /*rtl: -4px */
  font-size: 16px;
  color: #343a40;
}

.form-radio-outline .form-check-input {
  border-width: 2px;
  background-color: #fff;
}
.form-radio-outline .form-check-input:active {
  filter: none;
}
.form-radio-outline .form-check-input:checked {
  background-color: #fff !important;
}
.form-radio-outline .form-check-input:checked[type=checkbox] {
  background-image: none;
}
.form-radio-outline .form-check-input:checked:after {
  position: absolute;
  content: "\f0765";
  font-family: "Material Design Icons";
  top: 6px !important;
  left: 4px;
  font-size: 6px;
}

.form-check-primary .form-check-input:checked {
  background-color: #0078d4;
  border-color: #0078d4;
}

.form-radio-primary .form-check-input:checked {
  border-color: #0078d4;
  background-color: #0078d4;
}
.form-radio-primary .form-check-input:checked:after {
  color: #0078d4;
}

.form-check-secondary .form-check-input:checked {
  background-color: #74788d;
  border-color: #74788d;
}

.form-radio-secondary .form-check-input:checked {
  border-color: #74788d;
  background-color: #74788d;
}
.form-radio-secondary .form-check-input:checked:after {
  color: #74788d;
}

.form-check-success .form-check-input:checked {
  background-color: #51d28c;
  border-color: #51d28c;
}

.form-radio-success .form-check-input:checked {
  border-color: #51d28c;
  background-color: #51d28c;
}
.form-radio-success .form-check-input:checked:after {
  color: #51d28c;
}

.form-check-info .form-check-input:checked {
  background-color: #5fd0f3;
  border-color: #5fd0f3;
}

.form-radio-info .form-check-input:checked {
  border-color: #5fd0f3;
  background-color: #5fd0f3;
}
.form-radio-info .form-check-input:checked:after {
  color: #5fd0f3;
}

.form-check-warning .form-check-input:checked {
  background-color: #f7cc53;
  border-color: #f7cc53;
}

.form-radio-warning .form-check-input:checked {
  border-color: #f7cc53;
  background-color: #f7cc53;
}
.form-radio-warning .form-check-input:checked:after {
  color: #f7cc53;
}

.form-check-danger .form-check-input:checked {
  background-color: #f34e4e;
  border-color: #f34e4e;
}

.form-radio-danger .form-check-input:checked {
  border-color: #f34e4e;
  background-color: #f34e4e;
}
.form-radio-danger .form-check-input:checked:after {
  color: #f34e4e;
}

.form-check-pink .form-check-input:checked {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.form-radio-pink .form-check-input:checked {
  border-color: #e83e8c;
  background-color: #e83e8c;
}
.form-radio-pink .form-check-input:checked:after {
  color: #e83e8c;
}

.form-check-light .form-check-input:checked {
  background-color: #f5f6f8;
  border-color: #f5f6f8;
}

.form-radio-light .form-check-input:checked {
  border-color: #f5f6f8;
  background-color: #f5f6f8;
}
.form-radio-light .form-check-input:checked:after {
  color: #f5f6f8;
}

.form-check-dark .form-check-input:checked {
  background-color: #343a40;
  border-color: #343a40;
}

.form-radio-dark .form-check-input:checked {
  border-color: #343a40;
  background-color: #343a40;
}
.form-radio-dark .form-check-input:checked:after {
  color: #343a40;
}

.form-check-purple .form-check-input:checked {
  background-color: #564ab1;
  border-color: #564ab1;
}

.form-radio-purple .form-check-input:checked {
  border-color: #564ab1;
  background-color: #564ab1;
}
.form-radio-purple .form-check-input:checked:after {
  color: #564ab1;
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

.form-switch-md {
  font-size: 20px;
  min-height: 26px;
  line-height: 26px;
}
.form-switch-md .form-check-label {
  font-size: 0.875rem;
  vertical-align: middle;
}

.form-switch-lg {
  font-size: 26px;
  min-height: 36px;
  line-height: 36px;
}
.form-switch-lg .form-check-label {
  font-size: 0.875rem;
  vertical-align: middle;
}

.input-group-text {
  margin-bottom: 0px;
}

.card-radio {
  background-color: #fff;
  border: 2px solid #eff0f2;
  border-radius: 0.25rem;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-radio:hover {
  cursor: pointer;
}

.card-radio-label {
  display: block;
}

.card-radio-input {
  display: none;
}
.card-radio-input:checked + .card-radio {
  border-color: #0078d4 !important;
}

.form-filter-label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
}

.action-btn {
  color: #ee8134;
  font-size: 13px;
}

.time-picker__wrapper .ngb-tp .btn {
  padding: 0;
}

.api-key-dialog .copy-key-value {
  color: #989da8;
}
.api-key-dialog .key-value {
  max-height: 100px;
  min-height: 75px;
}

.drop-file-wrapper .drop-files-zone {
  border: none;
  background: none !important;
}
.drop-file-wrapper .drop-files-zone .drop-files-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafafb;
  border-radius: 6px;
  border: 2px dashed #bdc1ca;
  padding: 30px 0;
}
.drop-file-wrapper.active .drop-files-content {
  border-color: #0a58ca;
}
.drop-file-wrapper.disabled {
  pointer-events: none;
  opacity: 0.7;
}

[data-layout-mode=dark] .form-switch .form-check-input,
[data-layout-mode=dark] .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e2e5e8'/%3e%3c/svg%3e");
}
[data-layout-mode=dark] .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e2e5e8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
[data-layout-mode=dark] tag-input-form input {
  background-color: #033350;
  color: #ffffff;
}
[data-layout-mode=dark] .form-check-input {
  color: #ffffff;
  border-color: #ffffff;
}
[data-layout-mode=dark] .drop-file-wrapper .drop-files-zone .drop-files-content {
  background-color: #033350;
  border-color: #043a5a;
}
[data-layout-mode=dark] .drop-file-wrapper.active .drop-files-content {
  border-color: #0a58ca;
}
[data-layout-mode=dark] button.refresh-button {
  background-color: #043a5a;
}

.main-chart .chart-border-left {
  border-left: 1.4px solid var(--bs-border-color);
  padding: 2px 20px;
}
@media (max-width: 575.98px) {
  .main-chart .chart-border-left {
    border: 0;
  }
}
.main-chart .chart-border-left:last-child {
  margin-right: 0px;
}

.widget-chat {
  height: 300px !important;
}

.widget-chat-list {
  height: 373px !important;
}

@media (min-width: 992px) {
  .map-widget {
    border-right: 1px solid var(--bs-border-color);
  }
}

.button-items {
  margin-left: -8px;
  margin-bottom: -12px;
}
.button-items .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}

.search-box .form-control {
  border-radius: 30px;
  padding-left: 40px;
}
.search-box .search-icon {
  font-size: 16px;
  position: absolute;
  left: 13px;
  top: 0;
  line-height: 38px;
}

.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.icon-demo-content {
  text-align: center;
  color: #adb5bd;
}
.icon-demo-content i,
.icon-demo-content svg {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  font-size: 24px;
  margin: 0px auto 16px;
  border: 2px solid var(--bs-gray-300);
  color: #74788d;
  border-radius: 4px;
  transition: all 0.4s;
}
.icon-demo-content svg {
  padding: 6px;
}
.icon-demo-content .col-lg-4 {
  margin-top: 24px;
}
.icon-demo-content .col-lg-4:hover i,
.icon-demo-content .col-lg-4:hover svg {
  color: #fff;
  background-color: #0078d4;
  border-color: #0078d4;
  transform: scale(1.15);
}

.grid-structure .grid-container {
  background-color: #f8f9fa;
  margin-top: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 10px 20px;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.ribbon {
  position: absolute;
  right: -26px;
  top: 20px;
  transform: rotate(45deg);
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 1px 22px;
  font-size: 13px;
  font-weight: 500;
}

@media print {
  .vertical-menu,
  .right-bar,
  .page-title-box,
  .page-subtitle,
  .navbar-header,
  .footer {
    display: none !important;
  }
  .card-body,
  .main-content,
  .right-bar,
  .page-content,
  body {
    padding: 0;
    margin: 0;
  }
  .card {
    border: 0;
  }
}
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto; /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 8px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7 !important;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

.custom-scrollbar {
  overflow: auto;
  overflow: overlay;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  position: absolute;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(162, 173, 183, 0.6117647059);
  opacity: 0.5;
  cursor: pointer;
  border-radius: 6px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}

/* ==============
  Calendar
===================*/
.fc td,
.fc th {
  border: 1px solid var(--bs-border-color);
}

.fc .fc-toolbar h2 {
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .fc .fc-toolbar .fc-left,
  .fc .fc-toolbar .fc-right,
  .fc .fc-toolbar .fc-center {
    float: none;
    display: block;
    text-align: center;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc .fc-toolbar .fc-today-button {
    display: none;
  }
}
.fc .fc-toolbar .btn {
  text-transform: capitalize;
}
.fc .fc-col-header-cell {
  background-color: #f5f6f8;
}
.fc .fc-col-header-cell-cushion {
  display: block;
  padding: 8px 4px;
}
.fc .fc-daygrid-day-number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  margin: 2px;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(0, 120, 212, 0.1);
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background-color: #0078d4;
  color: #fff;
}
.fc .fc-list-event:hover td {
  background: transparent;
}
.fc .fc-list-event-dot {
  border-color: var(--bs-border-color);
}
.fc .fc-list-event-title a {
  color: #fff !important;
}
.fc .fc-col-header,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-sync-table {
  width: 100% !important;
}

.table-bordered {
  border: 1px solid var(--bs-border-color);
}

.fc-theme-bootstrap a:not([href]) {
  color: var(--bs-body-color);
}

.fc-event {
  color: #fff;
}

.fc th.fc-widget-header {
  background: #eff0f2;
  color: #495057;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: var(--bs-border-color);
}
.fc-unthemed td.fc-today {
  background: #fbfbfc;
}

.fc-button {
  background: #fff;
  border-color: var(--bs-border-color);
  color: #495057;
  text-transform: capitalize;
  box-shadow: none;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #0078d4;
  color: #fff;
  text-shadow: none;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-event,
.fc-event-dot {
  background-color: #0078d4;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background-color: #0078d4;
}

.fc-daygrid-dot-event {
  color: #fff !important;
}
.fc-daygrid-dot-event .fc-event-title {
  font-weight: 600;
}

.fc-daygrid-event-dot {
  border-color: #fff !important;
  color: #fff !important;
}

.fc-event .fc-content {
  color: #fff;
}

#external-events .external-event {
  text-align: left;
  padding: 8px 16px;
  margin: 6px 0;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark .fc-content {
  color: #f5f6f8;
}

[dir=rtl] .fc-header-toolbar {
  direction: ltr !important;
}
[dir=rtl] .fc-toolbar > * > :not(:first-child) {
  margin-left: 0.75em;
}

@media (max-width: 575.98px) {
  .fc-toolbar {
    flex-direction: column;
    gap: 16px;
  }
}

[data-layout-mode=dark] .fc-col-header-cell {
  background-color: var(--bs-gray-300);
}

.noUi-connect {
  background: #0078d4;
}

.noUi-horizontal {
  height: 8px;
}
.noUi-horizontal .noUi-handle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  right: -10px;
  top: -7px;
}
.noUi-horizontal .noUi-handle::before, .noUi-horizontal .noUi-handle::after {
  display: none;
}
.noUi-horizontal .noUi-handle:focus {
  outline: 0;
}

.noUi-pips-horizontal {
  height: 50px;
}

.noUi-vertical {
  width: 8px;
}
.noUi-vertical .noUi-handle {
  height: 20px;
  width: 20px;
  right: -7px;
  top: -12px;
  border-radius: 50%;
}
.noUi-vertical .noUi-handle::before, .noUi-vertical .noUi-handle::after {
  display: none;
}
.noUi-vertical .noUi-handle:focus {
  outline: 0;
}

.noUi-value {
  font-size: 12px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 6px;
}
.noUi-marker-horizontal.noUi-marker {
  display: none;
}

.noUi-target {
  background-color: #f5f6f8;
  border-color: #f5f6f8;
  box-shadow: none;
}

.noUi-touch-area:focus {
  outline: 0;
}

#red,
#green,
#blue {
  margin: 10px;
  display: inline-block;
  height: 200px;
}

#colorpicker {
  height: 240px;
  width: 310px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #eff0f2;
}

#result {
  margin: 60px 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #eff0f2;
  box-shadow: 0 0 3px;
  border-radius: 7px;
}

#red .noUi-connect {
  background: #f34e4e;
}

#green .noUi-connect {
  background: #51d28c;
}

#blue .noUi-connect {
  background: #0078d4;
}

.form-control.keyboard {
  max-width: 340px !important;
}

#input-select,
#input-number {
  padding: 7px;
  margin: 15px 5px 5px;
  width: 70px;
}

.example-val {
  font-size: 12px;
  color: var(--bs-text-muted);
  display: block;
  margin: 15px 0;
}
.example-val:before {
  content: "Value: ";
  font-size: 12px;
  font-weight: 600;
}

.noUi-tooltip {
  display: none;
}

.noUi-active .noUi-tooltip {
  display: block;
}

.c-1-color {
  background: #f34e4e;
}

.c-2-color {
  background: #f7cc53;
}

.c-3-color {
  background: #51d28c;
}

.c-4-color {
  background: #0078d4;
}

.c-5-color {
  background: #564ab1;
}

#slider-toggle {
  height: 50px;
}
#slider-toggle.off .noUi-handle {
  border-color: #f34e4e;
}

dark [data-layout-mode=dark] .noUi-target {
  background-color: var(--bs-light);
  border-color: var(--bs-light);
}

.swal2-container .swal2-title {
  font-size: 24px;
  font-weight: 500;
}

.swal2-content {
  font-size: 16px;
}

.swal2-popup {
  background: var(--bs-gray-300) !important;
}

.swal2-title {
  color: var(--bs-gray-700);
}

.swal2-icon.swal2-question {
  border-color: #5fd0f3;
  color: #5fd0f3;
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #51d28c;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(81, 210, 140, 0.3);
}
.swal2-icon.swal2-warning {
  border-color: #f7cc53;
  color: #f7cc53;
}

.swal2-styled:focus {
  box-shadow: none;
}

.swal2-progress-steps .swal2-progress-step {
  background: #0078d4;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #0078d4;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step, .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: rgba(0, 120, 212, 0.3);
}
.swal2-progress-steps .swal2-progress-step-line {
  background: #0078d4;
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  border-left-color: #0078d4 !important;
  border-right-color: #0078d4 !important;
}

.swal2-file,
.swal2-input,
.swal2-textarea {
  border: 1px solid var(--bs-input-border-color);
}
.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  box-shadow: none;
  border-color: var(--bs-input-focus-border-color);
}

.alertify .ajs-header {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  color: var(--bs-gray-700);
}
.alertify .ajs-dialog {
  box-shadow: 0 2px 3px rgba(52, 58, 64, 0.06);
  background-color: var(--bs-modal-bg);
  border-radius: 0.4rem;
  overflow: hidden;
}
.alertify .ajs-dialog .ajs-header,
.alertify .ajs-dialog .ajs-footer {
  background-color: #fff;
}
.alertify .ajs-body {
  color: #495057;
}
.alertify .ajs-footer .ajs-buttons .ajs-button {
  font-size: 13px;
  color: var(--bs-gray-700);
  font-weight: 500;
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  color: #0078d4;
}
.alertify .ajs-footer .ajs-buttons.ajs-primary {
  text-align: right;
}
.alertify .ajs-body .ajs-content {
  padding: 16px 0px;
}
.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
  outline: none;
}
.alertify .ajs-body .ajs-content .ajs-input {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-input-bg);
  border: 1px solid var(--bs-input-border-color);
  border-radius: 0.25rem;
}
.alertify .ajs-commands {
  top: 16px;
  right: 16px;
  left: auto;
  margin: 0;
}
.alertify .ajs-commands button {
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;
  opacity: 0.5;
}

.alertify-notifier .ajs-message {
  background-color: #0078d4;
  border-color: #0078d4;
  color: #fff;
  text-shadow: none !important;
  border-radius: 4px;
}
.alertify-notifier .ajs-message.ajs-success {
  background-color: #51d28c;
  border-color: #51d28c;
}
.alertify-notifier .ajs-message.ajs-error {
  background-color: #f34e4e;
  border-color: #f34e4e;
}
.alertify-notifier .ajs-message.ajs-warning {
  background-color: #f7cc53;
  border-color: #f7cc53;
}
.alertify-notifier.ajs-right {
  right: 10px;
  left: auto;
}
.alertify-notifier.ajs-right .ajs-message {
  right: -320px;
  left: auto;
}
.alertify-notifier.ajs-right .ajs-message.ajs-visible {
  right: 290px;
  left: auto;
}
.alertify-notifier.ajs-left {
  left: 10px;
  right: auto;
}
.alertify-notifier.ajs-left .ajs-message {
  left: -300px;
  right: auto;
}
.alertify-notifier.ajs-left .ajs-message.ajs-visible {
  left: 0;
  right: auto;
}

body[data-layout-mode=dark] .alertify ajs-dialog .ajs-dialog {
  background-color: #032d46;
}
body[data-layout-mode=dark] .alertify .ajs-header,
body[data-layout-mode=dark] .alertify .ajs-footer {
  color: #eff0f2;
  background-color: #032d46;
  border-color: #043a5a;
}
body[data-layout-mode=dark] .alertify .ajs-body {
  color: #adb5bd;
}
body[data-layout-mode=dark] .alertify .ajs-body .ajs-content .ajs-input {
  color: #adb5bd;
  background-color: #033350;
  border: 1px solid #043a5a;
}
body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button {
  color: #adb5bd;
}
body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  color: #0078d4;
}
body[data-layout-mode=dark] .alertify .ajs-commands button {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;
}

.swiper-arrow .swiper-button-next,
.swiper-arrow .swiper-button-prev {
  height: 30px;
  width: 30px;
  margin: auto 0;
  color: #fff !important;
  background-color: #0086ee;
}
.swiper-arrow .swiper-button-next::after,
.swiper-arrow .swiper-button-prev::after {
  font-size: 18px;
}
.swiper-arrow .swiper-button-prev {
  position: absolute;
  left: 0;
}
.swiper-arrow .swiper-button-next {
  position: absolute;
  right: 0;
}
.swiper-arrow.arrow-sm .swiper-button-prev,
.swiper-arrow.arrow-sm .swiper-button-next {
  height: 24px;
  width: 24px;
}
.swiper-arrow.arrow-sm .swiper-button-prev::after,
.swiper-arrow.arrow-sm .swiper-button-next::after {
  font-size: 12px;
}

.swiper-pagination {
  transform: translate3d(0, 0, 0) translateX(-50%);
  display: inline-flex;
  width: auto !important;
  padding: 4px 4px;
  left: 50% !important;
  background-color: #fff;
  border-radius: 10px;
  right: auto !important;
}

.swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
  border: 1px solid #0078d4;
}

.swiper-pagination-bullet-active {
  background-color: #0078d4;
  opacity: 1;
  border: 1px solid #0078d4;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.progress-swiper .swiper-pagination-progressbar {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
  background-color: #f5f6f8;
}
.progress-swiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #0078d4;
}

.choices__inner {
  padding: 0.25rem 2.5rem 0.25rem 0.5rem;
  background-color: var(--bs-input-bg);
  vertical-align: middle;
  border-radius: 0.25rem;
  border: 1px solid var(--bs-input-border-color);
  min-height: 38px;
}

.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 0.25rem;
}
.choices[data-type*=select-one]:after {
  border: 0;
  border-color: #343a40 transparent transparent;
  right: 11.5px;
  left: auto;
  content: "\f0140";
  font-family: "Material Design Icons";
  width: auto;
  height: auto;
  margin-top: 0;
  top: 50%;
  transform: translateY(-50%);
}
.choices[data-type*=select-one].is-open:after {
  margin-top: 0;
  transform: translateY(-50%) rotate(-180deg);
}
.choices[data-type*=select-one] .choices__button {
  right: 0;
  left: auto;
  margin-right: 25px;
  margin-left: 0;
}
.choices[data-type*=select-one] .choices__input {
  background-color: transparent;
  border-color: var(--bs-gray-300);
  color: var(--bs-gray-600);
}

.choices__input {
  background-color: var(--bs-input-bg);
  margin-bottom: 0;
  color: var(--bs-gray-600);
}

.choices__list--multiple {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 6px;
}
.choices__list--multiple .choices__item {
  background-color: #0078d4;
  border-color: #0078d4;
  margin-bottom: 0;
  margin-right: 0;
  font-weight: 400;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #0078d4;
  border: 1px solid #0078d4;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: var(--bs-light);
}

.is-open .choices__inner,
.is-focused .choices__inner {
  border-color: var(--bs-input-focus-border-color);
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #74788d;
  border-color: #74788d;
}

.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  margin: 0 -4px 0 8px;
  border-right: 0;
}

.choices__list--dropdown {
  border-color: var(--bs-gray-300) !important;
  box-shadow: var(--bs-card-border-color);
  background-color: var(--bs-input-bg);
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: dropdown-link-hover-bg;
}

.is-open .choices__list--dropdown {
  border-color: #e2e5e8;
}

[data-layout-mode=dark] .choices__list--dropdown ::placeholder,
[data-layout-mode=dark] .choices__inner ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] .choices__list--dropdown ::-webkit-input-placeholder,
[data-layout-mode=dark] .choices__inner ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] .choices__list--dropdown ::-moz-placeholder,
[data-layout-mode=dark] .choices__inner ::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] .choices__list--dropdown :-ms-input-placeholder,
[data-layout-mode=dark] .choices__inner :-ms-input-placeholder {
  /* IE 10+ */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] .choices__list--dropdown :-moz-placeholder,
[data-layout-mode=dark] .choices__inner :-moz-placeholder {
  /* Firefox 18- */
  color: var(--bs-body-color);
}
[data-layout-mode=dark] .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: var(--bs-dropdown-link-hover-bg);
}
[data-layout-mode=dark] .choices__heading {
  border-color: var(--bs-border-color);
}

/* CSS Switch */
input[switch] {
  display: none;
}
input[switch] + label {
  font-size: 1em;
  line-height: 1;
  width: 56px;
  height: 24px;
  background-color: #e2e5e8;
  background-image: none;
  border-radius: 2rem;
  padding: 0.16667rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  font-weight: 500;
  transition: all 0.1s ease-in-out;
}
input[switch] + label:before {
  color: #343a40;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  right: 1px;
  margin: 3px;
  top: -2px;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}
input[switch] + label:after {
  content: "";
  position: absolute;
  left: 3px;
  background-color: #f5f6f8;
  box-shadow: none;
  border-radius: 2rem;
  height: 20px;
  width: 20px;
  top: 2px;
  transition: all 0.1s ease-in-out;
}
input[switch]:checked + label {
  background-color: #0078d4;
}

input[switch]:checked + label {
  background-color: #0078d4;
}
input[switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 3px;
}
input[switch]:checked + label:after {
  left: 33px;
  background-color: #f5f6f8;
}

input[switch=bool] + label {
  background-color: #f34e4e;
}

input[switch=bool] + label:before,
input[switch=bool]:checked + label:before,
input[switch=default]:checked + label:before {
  color: #fff;
}

input[switch=bool]:checked + label {
  background-color: #51d28c;
}

input[switch=default]:checked + label {
  background-color: #a2a2a2;
}

input[switch=primary]:checked + label {
  background-color: #0078d4;
}

input[switch=success]:checked + label {
  background-color: #51d28c;
}

input[switch=info]:checked + label {
  background-color: #5fd0f3;
}

input[switch=warning]:checked + label {
  background-color: #f7cc53;
}

input[switch=danger]:checked + label {
  background-color: #f34e4e;
}

input[switch=dark]:checked + label {
  background-color: #343a40;
}

.square-switch {
  margin-right: 7px;
}
.square-switch input[switch] + label,
.square-switch input[switch] + label:after {
  border-radius: 4px;
}

.gridjs-container {
  color: var(--bs-table-color);
}

.gridjs-wrapper {
  box-shadow: none;
  border: 1px solid var(--bs-table-border-color);
}
.gridjs-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}
.gridjs-wrapper::-webkit-scrollbar:vertical {
  width: 12px;
}
.gridjs-wrapper::-webkit-scrollbar:horizontal {
  height: 12px;
}
.gridjs-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(52, 58, 64, 0.075);
  border-radius: 10px;
  border: 2px solid var(--bs-card-bg);
}
.gridjs-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--bs-card-bg);
}

.gridjs-table {
  width: 100%;
}

.gridjs-tbody,
td.gridjs-td {
  background-color: transparent;
}

th.gridjs-th,
td.gridjs-td {
  border: 1px solid var(--bs-table-border-color);
  padding: 0.75rem 0.75rem;
}

th.gridjs-th {
  border-top: 0;
  color: var(--bs-table-color);
  background-color: var(--bs-table-hover-bg);
}
th.gridjs-th-sort {
  background-color: var(--bs-table-hover-bg);
}
th.gridjs-th-sort:focus, th.gridjs-th-sort:hover {
  background-color: var(--bs-table-hover-bg);
}

.gridjs-footer {
  box-shadow: none;
  border: 1px solid var(--bs-table-border-color);
  border-top: 0;
  background-color: transparent;
}

.gridjs-pagination .gridjs-pages button {
  border: none !important;
  border-radius: 30px !important;
  margin: 0 5px;
  min-width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
  line-height: 32px;
  background-color: transparent;
  color: var(--bs-pagination-color);
}
.gridjs-pagination .gridjs-pages button:disabled, .gridjs-pagination .gridjs-pages button:hover:disabled, .gridjs-pagination .gridjs-pages button[disabled] {
  color: var(--bs-pagination-disabled-color);
  background-color: transparent;
}
.gridjs-pagination .gridjs-pages button:hover {
  background-color: var(--bs-pagination-bg);
  color: var(--bs-pagination-hover-color);
}
.gridjs-pagination .gridjs-pages button:focus {
  box-shadow: none;
}
.gridjs-pagination .gridjs-pages button:last-child:hover, .gridjs-pagination .gridjs-pages button:first-child:hover {
  background-color: transparent;
}
.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: #0078d4;
  color: #fff;
  border-color: #0078d4;
}
.gridjs-pagination .gridjs-summary {
  color: var(--bs-gray-500);
}

input.gridjs-input {
  border-color: var(--bs-input-border-color);
  background-color: var(--bs-input-bg);
  color: var(--bs-body-color);
}
input.gridjs-input:focus {
  box-shadow: none;
  border-color: var(--bs-input-focus-border-color);
  background-color: var(--bs-input-bg);
}

[data-layout-mode=dark] .gridjs-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-300);
}

.pcr-app {
  background: #fff;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview {
  margin-right: 0.75em;
  margin-left: 0;
}
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity {
  margin-left: 0.75em;
  margin-right: 0;
}

.flatpickr-input[readonly] {
  background-color: var(--bs-input-bg);
}

.input[readonly] {
  background-color: var(--bs-input-bg);
}

.flatpickr-months,
.flatpickr-weekdays {
  background-color: #0078d4;
}

span.flatpickr-weekday {
  color: #fff;
  font-weight: 500;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: transparent !important;
}

.flatpickr-am-pm:focus, .flatpickr-am-pm:hover,
.numInput:focus,
.numInput:hover,
.numInputWrapper:focus,
.numInputWrapper:hover {
  background-color: transparent !important;
}

.flatpickr-weekdays {
  height: 36px !important;
  border-bottom: 1px solid var(--bs-gray-200);
}

.flatpickr-day {
  color: var(--bs-gray-700);
}
.flatpickr-day:hover, .flatpickr-day:focus {
  background-color: rgba(var(--bs-light), 1) !important;
}
.flatpickr-day.today {
  border-color: #0078d4 !important;
  background-color: rgba(0, 120, 212, 0.1);
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: var(--bs-gray-800) !important;
  background-color: rgba(0, 120, 212, 0.15) !important;
}
.flatpickr-day.selected {
  background-color: #0078d4 !important;
  border-color: #0078d4 !important;
  color: #fff !important;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--bs-gray-500) !important;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background-color: var(--bs-gray-200) !important;
  border-color: var(--bs-gray-200);
}
.flatpickr-day.inRange {
  box-shadow: -5px 0 0 var(--bs-light), 5px 0 0 var(--bs-light);
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background-color: #0078d4 !important;
  border-color: #0078d4 !important;
  color: #fff !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-month {
  color: rgba(255, 255, 255, 0.9) !important;
  fill: rgba(255, 255, 255, 0.9) !important;
}

.flatpickr-monthDropdown-month {
  color: rgba(0, 0, 0, 0.8);
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}

.flatpickr-time input,
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: var(--bs-gray-800) !important;
}

.flatpickr-calendar {
  background-color: var(--bs-light) !important;
  box-shadow: 1px 0 0 var(--bs-gray-200), -1px 0 0 var(--bs-gray-200), 0 1px 0 var(--bs-gray-200), 0 -1px 0 var(--bs-gray-200), 0 3px 13px rgba(0, 0, 0, 0.08) !important;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid var(--bs-gray-200) !important;
}

.flatpickr-months {
  border-radius: 5px 5px 0px 0px;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: rgba(255, 255, 255, 0.9) !important;
}

[data-layout-mode=dark] .flatpickr-day {
  color: #adb5bd;
}
[data-layout-mode=dark] .flatpickr-day:hover, [data-layout-mode=dark] .flatpickr-day:focus {
  background-color: rgba(0, 120, 212, 0.08);
  border-color: rgba(0, 120, 212, 0);
}
[data-layout-mode=dark] .flatpickr-day.flatpickr-disabled, [data-layout-mode=dark] .flatpickr-day.flatpickr-disabled:hover, [data-layout-mode=dark] .flatpickr-day.prevMonthDay, [data-layout-mode=dark] .flatpickr-day.nextMonthDay, [data-layout-mode=dark] .flatpickr-day.notAllowed, [data-layout-mode=dark] .flatpickr-day.notAllowed.prevMonthDay, [data-layout-mode=dark] .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(226, 229, 232, 0.25) !important;
}
[data-layout-mode=dark] .flatpickr-day.inRange,
[data-layout-mode=dark] .flatpickr-day.prevMonthDay.inRange,
[data-layout-mode=dark] .flatpickr-day.nextMonthDay.inRange,
[data-layout-mode=dark] .flatpickr-day.today.inRange,
[data-layout-mode=dark] .flatpickr-day.prevMonthDay.today.inRange,
[data-layout-mode=dark] .flatpickr-day.nextMonthDay.today.inRange,
[data-layout-mode=dark] .flatpickr-day:hover,
[data-layout-mode=dark] .flatpickr-day.prevMonthDay:hover,
[data-layout-mode=dark] .flatpickr-day.nextMonthDay:hover,
[data-layout-mode=dark] .flatpickr-day:focus,
[data-layout-mode=dark] .flatpickr-day.prevMonthDay:focus,
[data-layout-mode=dark] .flatpickr-day.nextMonthDay:focus {
  background-color: rgba(0, 120, 212, 0.08) !important;
  border-color: rgba(0, 120, 212, 0);
}
[data-layout-mode=dark] .range-datepicker-form .btn {
  border-color: #043a5a;
  background-color: #033350;
}

.ck.ck-toolbar {
  background-color: rgba(245, 246, 248, 0.75) !important;
  border: 1px solid var(--bs-input-border-color) !important;
}
.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  flex-wrap: wrap !important;
}
.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: transparent !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  border-top: 0 !important;
  background-color: var(--bs-card-bg) !important;
  border-color: var(--bs-input-border-color) !important;
  box-shadow: none !important;
}
.ck.ck-dropdown__panel, .ck.ck-list {
  background: #fff !important;
}
.ck.ck-reset_all, .ck.ck-reset_all * {
  color: var(--bs-body-color) !important;
}
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne, .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  left: 0;
  right: auto !important;
}
.ck.ck-editor__editable_inline[dir=ltr] {
  text-align: left !important;
}
.ck.ck-dropdown__panel {
  box-shadow: 0 2px 3px rgba(52, 58, 64, 0.06) !important;
  border-radius: 0.25rem !important;
  border: 1px solid #e2e5e8 !important;
}

.ck-editor__editable {
  min-height: 245px !important;
}

.ck.ck-button.ck-on:not(.ck-disabled):hover, .ck.ck-button.ck-on:not(.ck-disabled):active,
a.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):active {
  background: #f5f6f8 !important;
  box-shadow: none !important;
}
.ck.ck-button:focus, .ck.ck-button:active,
a.ck.ck-button:focus,
a.ck.ck-button:active {
  background: #f5f6f8 !important;
  border-color: #f5f6f8 !important;
}

.ck.ck-tooltip .ck-tooltip__text {
  background: #343a40 !important;
  color: #f5f6f8 !important;
}

.ck.ck-toolbar .ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: rgba(245, 246, 248, 0.75) !important;
}

[dir=rtl] .ck.ck-toolbar > .ck-toolbar__items {
  flex-direction: row-reverse;
}

.ql-editor {
  text-align: left;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
  padding-right: 0;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}

.ql-container {
  font-family: "Inter", sans-serif;
}
.ql-container.ql-snow {
  border-color: var(--bs-input-border-color);
}

.ql-bubble {
  border: 1px solid var(--bs-input-border-color);
  border-radius: 0.25rem;
}

.ql-toolbar {
  font-family: "Inter", sans-serif !important;
}
.ql-toolbar span {
  outline: none !important;
  color: var(--bs-gray-700) !important;
}
.ql-toolbar span:hover {
  color: #0078d4 !important;
}
.ql-toolbar.ql-snow {
  border-color: var(--bs-input-border-color);
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
  box-shadow: 0 2px 3px rgba(52, 58, 64, 0.06);
  border-radius: 0.25rem;
}

.ql-snow .ql-stroke,
.ql-snow .ql-script,
.ql-snow .ql-strike svg {
  stroke: var(--bs-gray-700);
}
.ql-snow .ql-fill {
  fill: var(--bs-gray-700);
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: 0;
  left: auto;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}

.ql-snow .ql-picker-options {
  background-color: var(--bs-light);
  border-color: var(--bs-light) !important;
}

[data-layout-mode=dark] .ck-toolbar {
  background-color: var(--bs-light) !important;
}
[data-layout-mode=dark] .ck.ck-toolbar .ck.ck-button.ck-on,
[data-layout-mode=dark] a.ck.ck-button.ck-on,
[data-layout-mode=dark] .ck.ck-button:not(.ck-disabled):hover,
[data-layout-mode=dark] a.ck.ck-button:not(.ck-disabled):hover {
  background: rgba(245, 246, 248, 0.1) !important;
}

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed var(--bs-gray-300);
  background: #fff;
  border-radius: 6px;
}
.dropzone .dz-message {
  font-size: 24px;
  width: 100%;
}

[data-layout-mode=dark] .dropzone {
  background: rgba(255, 255, 255, 0.02);
}

.wizard-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.wizard-nav .wizard-list-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.wizard-nav .wizard-list-item .list-item {
  width: 100%;
  font-size: 14px;
  position: relative;
}
.wizard-nav .step-icon {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 54px;
  border: 1px solid rgba(0, 120, 212, 0.2);
  color: #0078d4;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  font-size: 20px;
}
@media (max-width: 575.98px) {
  .wizard-nav .step-icon {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }
}
.wizard-nav .step-title {
  margin-left: 6px;
}
.wizard-nav .wizard-list-item:last-child .list-item::after {
  display: none;
}
.wizard-nav .wizard-list-item .list-item.done .step-icon {
  background-color: #0078d4;
  color: #fff;
}
.wizard-nav .wizard-list-item .list-item.done .uil:before {
  content: "\e9c3";
}
.wizard-nav .list-item {
  font-size: 14px;
  position: relative;
}
@media (max-width: 575.98px) {
  .wizard-nav .list-item {
    padding: 0.5rem;
  }
}
.wizard-nav .list-item::after {
  content: "";
  position: absolute;
  width: 75%;
  height: 2px;
  background-color: var(--bs-card-border-color);
  left: 62%;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 575.98px) {
  .wizard-nav .list-item::after {
    display: none;
  }
}
.wizard-nav .list-item.active {
  color: #495057;
  background-color: transparent;
}
.wizard-nav .list-item.active .step-icon {
  background-color: rgba(0, 120, 212, 0.2);
  color: #0078d4;
  border-color: rgba(0, 120, 212, 0.2);
}

.wizard-tab {
  display: none;
}

.apex-charts {
  min-height: 10px !important;
}
.apex-charts text {
  font-family: "Inter", sans-serif !important;
  fill: var(--bs-gray-500);
}
.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: "Inter", sans-serif !important;
}

.apexcharts-tooltip * {
  font-family: "Inter", sans-serif !important;
  color: #74788d !important;
}

.apexcharts-tooltip.apexcharts-theme-dark * {
  color: #fff !important;
}

.apexcharts-legend-series {
  font-weight: 500;
}

.apexcharts-bar-series text {
  fill: #fff !important;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: #f8f9fa;
}

.apexcharts-legend-text {
  color: var(--bs-gray-600) !important;
  font-family: "Inter", sans-serif !important;
  font-size: 13px !important;
}

.apexcharts-pie-label {
  fill: var(--bs-white) !important;
}

.apexcharts-title-text,
.apexcharts-subtitle-text {
  fill: #adb5bd;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: "Inter", sans-serif !important;
  fill: var(--bs-gray-500);
}
.apexcharts-yaxis line,
.apexcharts-xaxis line {
  stroke: var(--bs-gray-300);
}

.apexcharts-yaxis-title {
  font-weight: 500;
}

#treemap-chart.apex-charts text {
  fill: var(--bs-white) !important;
}

.apexcharts-treemap-rect {
  stroke: var(--bs-card-bg);
}

.apexcharts-series .apexcharts-data-labels text {
  fill: #fff !important;
}

.apexcharts-radar-series polygon,
.apexcharts-radar-series line {
  fill: var(--bs-card-bg);
  stroke: var(--bs-card-border-color);
}

.apexcharts-pie circle,
.apexcharts-pie line {
  stroke: var(--bs-card-border-color);
}

#dynamicloadedchart-wrap {
  margin: 0px auto;
  max-width: 800px;
  position: relative;
}

.chart-box {
  padding-left: 0;
}

#chart-year,
#chart-quarter {
  width: 96%;
  max-width: 48%;
  box-shadow: none;
  padding-left: 0;
  padding-top: 20px;
  background: var(--bs-card-bg);
  border: 1px solid var(--bs-border-color);
}

#chart-year {
  float: left;
  position: relative;
  transition: 1s ease transform;
  z-index: 3;
}
#chart-year.chart-quarter-activated {
  transform: translateX(0);
  transition: 1s ease transform;
}

#chart-quarter {
  float: left;
  position: relative;
  z-index: -2;
  transition: 1s ease transform;
}
#chart-quarter.active {
  transition: 1.1s ease-in-out transform;
  transform: translateX(0);
  z-index: 1;
}

@media screen and (min-width: 480px) {
  #chart-year {
    transform: translateX(50%);
  }
  #chart-quarter {
    transform: translateX(-50%);
  }
}
[data-layout-mode=dark] .apexcharts-gridline {
  stroke: var(--bs-gray-300);
}
[data-layout-mode=dark] .apexcharts-radialbar-track.apexcharts-track path {
  stroke: var(--bs-gray-300);
}
[data-layout-mode=dark] .apexcharts-tooltip {
  background-color: var(--bs-card-bg) !important;
  border: 1px solid var(--bs-border-color) !important;
}
[data-layout-mode=dark] .apexcharts-tooltip .apexcharts-tooltip-title {
  background-color: var(--bs-gray-300) !important;
  border-bottom: 1px solid var(--bs-border-color) !important;
}
[data-layout-mode=dark] .apexcharts-pie-area {
  stroke: var(--bs-gray-300);
}
[data-layout-mode=dark] .apexcharts-pie-label {
  fill: var(--bs-gray-600) !important;
}

.glightbox-clean .gslide-title {
  font-family: "Inter", sans-serif;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 18px;
}
.glightbox-clean .gslide-desc {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.glightbox-clean .gclose {
  border: 2px solid #fff;
  border-radius: 7px;
  right: 10px;
  left: auto;
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    right: 20px;
    left: auto;
  }
}
.glightbox-clean .gclose svg {
  width: 12px;
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #f8f9fa;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  background: #0078d4;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #0078d4;
}
.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #0078d4;
}

.jvm-tooltip {
  border-radius: 3px;
  background-color: #0078d4;
  font-family: "Inter", sans-serif;
  box-shadow: 0 5px 6px rgba(52, 58, 64, 0.1);
  padding: 3px 5px;
}

.leaflet-map {
  height: 300px;
}
.leaflet-map.leaflet-container {
  z-index: 99;
}

.home-btn {
  position: fixed;
  top: 15px;
  right: 25px;
}

.authentication-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authentication-bg .auth-logo .logo {
  margin: 0px auto;
}
.authentication-bg .auth-logo .auth-logo-light {
  display: none;
  margin: 0px auto;
}
.authentication-bg .auth-logo .auth-logo-dark {
  margin: 0px auto;
}
.authentication-bg .bg-overlay {
  opacity: 0.5;
}
.authentication-bg .auth-cover-card .authentication-bg {
  align-items: flex-start;
  justify-content: flex-start;
}
.authentication-bg .auth-cover-card .bg-overlay {
  opacity: 0.6;
}
.authentication-bg .auth-cover-card .form-floating label {
  color: rgba(255, 255, 255, 0.5);
}
.authentication-bg .auth-cover-card .form-floating .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.authentication-bg .auth-cover-card .form-floating .form-floating-icon {
  color: rgba(255, 255, 255, 0.5);
}

.form-floating-custom {
  position: relative;
}
.form-floating-custom > label {
  left: 48px;
}
.form-floating-custom > .form-control,
.form-floating-custom > .form-select {
  padding-left: 60px;
}
.form-floating-custom .form-floating-icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 56px;
  color: var(--bs-text-muted);
}

.error-font {
  font-size: 9rem;
  text-shadow: 4px 4px 6px rgba(33, 37, 41, 0.26);
}

.mover-animation {
  animation: mover 1s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.signin-other-title {
  position: relative;
}
.signin-other-title:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #eff0f2;
  top: 10px;
}
.signin-other-title .title {
  display: inline-block;
  position: relative;
  z-index: 9;
  background-color: #fff;
  padding: 2px 16px;
}

.auth-img {
  background-image: url("https://images.unsplash.com/photo-1593062096033-9a26b09da705?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
@media (max-width: 991.98px) {
  .auth-img {
    height: 250px;
  }
}

.task-board {
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  align-items: stretch;
}
.task-board::-webkit-scrollbar {
  -webkit-appearance: none;
}
.task-board::-webkit-scrollbar:vertical {
  width: 12px;
}
.task-board::-webkit-scrollbar:horizontal {
  height: 10px;
}
.task-board::-webkit-scrollbar-thumb {
  background-color: rgba(52, 58, 64, 0.2);
  border-radius: 10px;
  border: 2px solid #fff;
}
.task-board::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

.tasks {
  min-height: 300px;
}

.task-list {
  min-width: 280px;
  white-space: normal;
}
@media (min-width: 576px) {
  .task-list {
    width: 400px;
    min-width: 400px;
  }
}
.task-list:not(:last-child) {
  margin-right: 20px;
}
.task-list .tasklist-content {
  max-height: calc(100vh - 430px);
}
.task-list .task-box {
  border: 1px solid #eff0f2;
}
.task-list .task-box:last-child {
  margin-bottom: 0;
}

.gu-transit {
  border: 1px dashed #74788d !important;
  background-color: #f5f6f8 !important;
}

@media (min-width: 1200px) {
  .filemanager-sidebar {
    min-width: 230px;
    max-width: 230px;
  }
}
@media (min-width: 1366px) {
  .filemanager-sidebar {
    min-width: 280px;
    max-width: 280px;
  }
}
.filemanager-sidebar .alert-info .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.categories-list {
  padding: 4px 0;
}
.categories-list li a {
  display: block;
  padding: 8px 12px;
  color: #495057;
  font-weight: 500;
}
.categories-list li.active a {
  color: #0078d4;
}
.categories-list li ul {
  padding-left: 14px;
}
.categories-list li ul li a {
  padding: 4px 12px;
  color: var(--bs-text-muted);
  font-size: 13px;
  font-weight: 400;
}

/* ==============
  Email
===================*/
.email-leftbar {
  width: 236px;
  float: left;
  padding: 20px;
  border-radius: 5px;
}

.email-rightbar {
  margin-left: 254px;
}

.chat-user-box p.user-title {
  color: #343a40;
  font-weight: 500;
}
.chat-user-box p {
  font-size: 13px;
}

@media (max-width: 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }
  .email-rightbar {
    margin: 0;
  }
}
.mail-list a {
  display: block;
  color: #74788d;
  line-height: 24px;
  padding: 8px 5px;
}
.mail-list a.active {
  color: #f34e4e;
  font-weight: 500;
}

.message-list {
  display: block;
  padding-left: 0;
}
.message-list li {
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  cursor: default;
  transition-duration: 0.3s;
}
.message-list li a {
  color: #74788d;
}
.message-list li.selected {
  background-color: rgba(239, 240, 242, 0.6);
}
.message-list li:hover {
  background: #eff0f2;
  transition-duration: 0.05s;
}
.message-list li .col-mail {
  float: left;
  position: relative;
}
.message-list li .col-mail-1 {
  width: 320px;
}
.message-list li .col-mail-1 .star-toggle,
.message-list li .col-mail-1 .checkbox-wrapper-mail,
.message-list li .col-mail-1 .dot {
  display: block;
  float: left;
}
.message-list li .col-mail-1 .dot {
  border: 4px solid transparent;
  border-radius: 100px;
  margin: 22px 26px 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}
.message-list li .col-mail-1 .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
}
.message-list li .col-mail-1 .star-toggle {
  margin-top: 18px;
  margin-left: 5px;
}
.message-list li .col-mail-1 .title {
  position: absolute;
  top: 0;
  left: 110px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
}
.message-list li .col-mail-2 {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
}
.message-list li .col-mail-2 .subject,
.message-list li .col-mail-2 .date {
  position: absolute;
  top: 0;
}
.message-list li .col-mail-2 .subject {
  left: 0;
  right: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.message-list li .col-mail-2 .date {
  right: 0;
  width: 170px;
  padding-left: 80px;
}
.message-list li.active, .message-list li.active:hover {
  box-shadow: inset 3px 0 0 #0078d4;
}
.message-list li.unread {
  background-color: #eff0f2;
  font-weight: 500;
  color: #292d32;
}
.message-list li.unread a {
  color: #292d32;
  font-weight: 500;
}
.message-list .checkbox-wrapper-mail {
  cursor: pointer;
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  box-shadow: inset 0 0 0 1px var(--bs-gray-400);
  border-radius: 1px;
}
.message-list .checkbox-wrapper-mail input {
  opacity: 0;
  cursor: pointer;
}
.message-list .checkbox-wrapper-mail input:checked ~ label {
  opacity: 1;
}
.message-list .checkbox-wrapper-mail label {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0;
  transition-duration: 0.05s;
  top: 0;
}
.message-list .checkbox-wrapper-mail label:before {
  content: "\f012c";
  font-family: "Material Design Icons";
  top: 0;
  height: 20px;
  color: var(--bs-body-color);
  width: 20px;
  position: absolute;
  margin-top: -16px;
  left: 4px;
  font-size: 13px;
}

@media (max-width: 575.98px) {
  .message-list li .col-mail-1 {
    width: 200px;
  }
}
[data-layout-mode=dark] .message-list li:hover, [data-layout-mode=dark] .message-list li.unread {
  background: var(--bs-input-bg);
  color: var(--bs-body-color);
}
[data-layout-mode=dark] .message-list li.unread .date,
[data-layout-mode=dark] .message-list li.unread span {
  color: var(--bs-gray-400);
}
[data-layout-mode=dark] .message-list li a {
  color: var(--bs-gray-400);
}
[data-layout-mode=dark] .mail-list a {
  color: var(--bs-gray-400);
}

@media (min-width: 1200px) {
  .chat-wrapper .chat-leftsidebar {
    min-width: 380px;
    min-height: calc(100vh - 325px);
  }
  .chat-wrapper > .user-chat {
    max-width: calc(100% - 380px);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .chat-wrapper .chat-leftsidebar {
    min-width: 290px;
    min-height: calc(100vh - 270px);
  }
  .chat-wrapper > .user-chat {
    max-width: calc(100% - 290px);
  }
}
.chat-noti-dropdown.active:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #f34e4e;
  border-radius: 50%;
  right: 0;
}
.chat-noti-dropdown .btn {
  padding: 6px;
  box-shadow: none;
  font-size: 16px;
  color: var(--bs-btn-hover-color);
}
.chat-noti-dropdown .btn:hover {
  border-color: transparent;
  color: var(--bs-btn-hover-color);
}

.chat-search-box .form-control {
  border: 0;
}

.chat-message-list {
  max-height: calc(100vh - 315px);
}
@media (min-width: 992px) {
  .chat-message-list {
    max-height: calc(100vh - 300px);
  }
}

.chat-personas-list {
  overflow: auto;
  max-height: calc(100vh - 325px);
  padding-bottom: 40px;
}
@media (min-width: 992px) {
  .chat-personas-list {
    max-height: calc(100vh - 270px);
  }
}

.chat-list {
  margin: 0;
  max-height: calc(100vh - 350px);
}
.chat-list__item:first-child {
  margin-top: 0;
}
.chat-list__item:first-child a {
  margin-top: 0;
}
.chat-list__item.active a {
  background-color: rgba(0, 120, 212, 0.075);
  border-color: transparent;
}
.chat-list__item a {
  position: relative;
  display: block;
  color: var(--bs-text-muted);
  transition: all 0.4s;
  padding: 12px;
  border: 1px solid var(--bs-card-border-color);
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  margin: 12px 0px;
}
.chat-list__item a:hover {
  background-color: rgba(0, 120, 212, 0.075);
  border-color: transparent;
}
.chat-list__item .user-img {
  position: relative;
}
.chat-list__item .user-img .user-status {
  width: 10px;
  height: 10px;
  background-color: #51d28c;
  border-radius: 50%;
  border: 2px solid transparent;
  position: absolute;
  left: 0;
  bottom: 0;
}
.chat-list__item .user-img.online .user-status {
  background-color: #51d28c;
}
.chat-list__item .user-img.away .user-status {
  background-color: #f7cc53;
}
.chat-list__item.unread a {
  font-weight: 600;
  color: var(--bs-body-color);
}
.chat-list__item .unread-message {
  position: absolute;
  display: inline-block;
  right: 16px;
  top: 33px;
}

.group-list li a {
  border: 0;
}

.user-chat-nav .dropdown .nav-btn {
  height: 36px;
  width: 36px;
  line-height: 36px;
  box-shadow: none;
  padding: 0;
  font-size: 16px;
  border-radius: 50%;
  color: var(--bs-btn-hover-color);
}
.user-chat-nav .dropdown .nav-btn.btn:hover, .user-chat-nav .dropdown .nav-btn.btn:active, .user-chat-nav .dropdown .nav-btn.btn:visited, .user-chat-nav .dropdown .nav-btn.btn:active:focus, .user-chat-nav .dropdown .nav-btn.btn:focus {
  border-color: transparent !important;
}

.contact-list {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--bs-text-muted);
  font-weight: 600;
}

.chat-conversation {
  height: calc(100vh - 300px);
}
@media (min-width: 992px) {
  .chat-conversation {
    height: calc(100vh - 360px);
  }
}
.chat-conversation li {
  clear: both;
}
.chat-conversation .chat-day-title {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
  border: none;
}
.chat-conversation .chat-day-title .title {
  display: inline-block;
  background-color: #f5f6f8;
  position: relative;
  z-index: 1;
  padding: 4px 16px;
  border-radius: 30px;
}
.chat-conversation .chat-day-title:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: var(--bs-card-border-color);
  top: 16px;
}
.chat-conversation .chat-day-title .badge {
  font-size: 12px;
}
.chat-conversation .ctext-wrap-content_max-width {
  max-width: calc(100% - 70px);
}
.chat-conversation .chat-persona img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.chat-conversation .conversation-list {
  margin-bottom: 24px;
  position: relative;
}
.chat-conversation .conversation-list .ctext-wrap {
  display: flex;
  margin-bottom: 10px;
}
.chat-conversation .conversation-list .ctext-wrap-content {
  padding: 6px 10px;
  background-color: rgba(0, 120, 212, 0.1);
  border-radius: 0px 8px 8px 8px;
  color: var(--bs-text-muted);
  position: relative;
  margin-left: 6px;
}
.chat-conversation .conversation-list .ctext-wrap-content:before {
  content: "";
  position: absolute;
  border: 5px solid transparent;
  border-right-color: rgba(0, 120, 212, 0.1);
  border-top-color: rgba(0, 120, 212, 0.1);
  left: -10px;
  top: 0;
}
.chat-conversation .conversation-list .conversation-name {
  margin-bottom: 4px;
  display: flex;
  font-size: 13px;
}
.chat-conversation .conversation-list .conversation-name .time {
  font-size: 12px;
  color: var(--bs-text-muted);
  margin-left: 8px;
  font-weight: 400;
}
.chat-conversation .conversation-list .conversation-name .user-name {
  color: var(--bs-gray-700);
}
.chat-conversation .conversation-list .dropdown .dropdown-toggle {
  font-size: 18px;
  padding: 4px;
  color: #74788d;
}
@media (max-width: 575.98px) {
  .chat-conversation .conversation-list .dropdown .dropdown-toggle {
    display: none;
  }
}
.chat-conversation .conversation-list .chat-time {
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
}
.chat-conversation .conversation-list .message-img {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.chat-conversation .conversation-list .message-img .message-img-list {
  position: relative;
}
.chat-conversation .conversation-list .message-img img {
  max-width: 80px;
}
.chat-conversation .right .conversation-list {
  float: right;
  text-align: right;
}
.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content {
  order: 2;
  background-color: #f5f6f8;
  text-align: right;
  border-radius: 8px 0px 8px 8px;
  margin-left: 0;
  margin-right: 6px;
}
.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content:before {
  border: 5px solid transparent;
  border-top-color: #f5f6f8;
  border-left-color: #f5f6f8;
  left: auto;
  right: -10px;
}
.chat-conversation .right .conversation-list .ctext-wrap .conversation-name {
  justify-content: flex-end;
}
.chat-conversation .right .conversation-list .ctext-wrap .conversation-name .time {
  order: 1;
  margin-left: 0;
  margin-right: 8px;
}
.chat-conversation .right .conversation-list .ctext-wrap .conversation-name .user-name {
  order: 2;
}
.chat-conversation .right .conversation-list .dropdown {
  order: 1;
}
.chat-conversation .right .conversation-list .chat-persona {
  order: 3;
}
.chat-conversation .right .message-bottom {
  flex-direction: row-reverse;
}

.chat-input-section {
  border-top: 1px solid #eff0f2;
}

.chat-input {
  background-color: #f5f6f8 !important;
  border-color: #f5f6f8 !important;
}

@media (max-width: 575.98px) {
  .chat-send {
    min-width: auto;
  }
}

[data-layout-mode=dark] .chat-day-title .title {
  background-color: var(--bs-light);
}
[data-layout-mode=dark] .right .conversation-list .ctext-wrap .ctext-wrap-content {
  background-color: var(--bs-light);
}
[data-layout-mode=dark] .right .conversation-list .ctext-wrap .ctext-wrap-content:before {
  border-top-color: var(--bs-light);
  border-left-color: var(--bs-light);
}
[data-layout-mode=dark] .chat-input {
  background-color: var(--bs-card-body) !important;
  border-color: var(--bs-card-border-color) !important;
}
[data-layout-mode=dark] .chat-input-section {
  border-top: 1px solid var(--bs-border-color);
}

.message-bottom {
  min-height: 24px;
}
.message-bottom .thumbs {
  padding-top: 2px;
}
.message-bottom .thumbs .mdi {
  cursor: pointer;
}
.message-bottom .thumbs .mdi-thumb-up {
  margin-top: -3px;
}

.countdownlist {
  text-align: center;
  display: flex;
  gap: 24px;
}
.countdownlist .countdownlist-item {
  width: 25%;
}
.countdownlist .countdownlist-item:last-of-type .count-num::after {
  display: none;
}
.countdownlist .count-title {
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
  color: rgba(52, 58, 64, 0.5);
  text-transform: uppercase;
}
.countdownlist .count-num {
  background-color: #fff;
  padding: 16px 8px;
  position: relative;
  border-radius: 0.25rem;
  box-shadow: 0 2px 3px rgba(52, 58, 64, 0.06);
  font-weight: 600;
  font-size: 32px;
}
@media (max-width: 575.98px) {
  .countdownlist .count-num {
    font-size: 18px;
  }
}
.countdownlist .count-num::after {
  content: ":";
  font-size: 20px;
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  color: #343a40;
}

.countdown-endtxt {
  font-size: 24px;
  font-weight: 600;
}

.countdown-input-group {
  max-width: 350px;
}

.hori-timeline .event-list {
  position: relative;
  text-align: center;
  padding: 24px;
}
.hori-timeline .event-list::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  top: 7px;
  background-color: var(--bs-border-color);
}
.hori-timeline .event-list:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #0078d4;
  border: 5px solid var(--bs-gray-300);
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}
.hori-timeline .swiper-button-next:after,
.hori-timeline .swiper-button-prev:after {
  font-size: 16px;
}
.hori-timeline.timeline-card .event-list-item {
  background-color: var(--bs-gray-300);
  padding: 24px;
  border-radius: 7px;
  transition: all 0.2s;
}
.hori-timeline.timeline-card .event-list::after {
  display: none;
}
.hori-timeline.timeline-card .event-list::before {
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.verti-timeline {
  position: relative;
  margin-bottom: 30px;
}
.verti-timeline:before {
  content: "";
  position: absolute;
  width: 2px;
  top: 16px;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 120, 212, 0.07);
}
.verti-timeline .timeline-item {
  display: flex;
}
.verti-timeline .timeline-block {
  width: 100%;
}
.verti-timeline .time-show-btn {
  margin-bottom: 30px;
}
.verti-timeline .timeline-box {
  margin: 20px 0;
  position: relative;
  margin-left: 45px;
  box-shadow: none;
  border: 1px solid var(--bs-border-color);
  border-radius: 6px;
  border-left: 2px solid #0078d4;
}
.verti-timeline .timeline-box::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: #0078d4;
  border-radius: 50%;
  display: block;
  border: 4px solid rgba(247, 248, 250, 0.8);
  left: -53px;
  text-align: center;
  top: 32px;
  z-index: 9;
}
.verti-timeline .timeline-box:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #0078d4;
  top: 30px;
  left: -20px;
}
.verti-timeline .timeline-box .timeline-date {
  color: var(--bs-text-muted);
}
.verti-timeline .timeline-album {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.verti-timeline .timeline-album a {
  display: block;
}
.verti-timeline .timeline-album a img {
  height: 40px;
  width: auto;
  border-radius: 4px;
}

@media (min-width: 768px) {
  .verti-timeline .timeline-block {
    width: 50%;
  }
  .verti-timeline:before {
    left: 50%;
  }
  .verti-timeline .time-show-btn {
    position: relative;
    left: 67px;
    text-align: right;
  }
  .timeline-item::before {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-item.left {
    text-align: right;
  }
  .timeline-item.left::after {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-item.left::before {
    display: none;
  }
  .timeline-item .timeline-box {
    margin-left: 45px;
  }
  .timeline-item.left .timeline-box {
    margin-left: 0;
    margin-right: 45px;
    border-left: 1px solid var(--bs-border-color);
    border-right: 2px solid #0078d4;
  }
  .timeline-item.left .timeline-box:before {
    left: auto;
    right: -55px;
  }
  .timeline-item.left .timeline-box:after {
    left: auto;
    right: -20px;
    border-right-color: transparent;
    border-left-color: #0078d4;
  }
}
.swiper-slide-arrow .swiper-button-prev {
  left: -9px !important;
}
.swiper-slide-arrow .swiper-button-next {
  right: -9px !important;
}

.verti-timeline.left-timeline .timeline-block {
  width: 100%;
}
.verti-timeline.left-timeline:before {
  left: 0;
}
.verti-timeline.left-timeline .time-show-btn {
  left: 0;
  text-align: left;
}
.verti-timeline.left-timeline .timeline-item::before, .verti-timeline.left-timeline .timeline-item.left::after {
  display: none;
}
.verti-timeline.left-timeline .timeline-item.left {
  text-align: left;
}
.verti-timeline.left-timeline .timeline-item.left .timeline-box {
  margin-right: 0;
  margin-left: 45px;
  border-right: 1px solid var(--bs-border-color);
  border-left: 2px solid #0078d4;
}
.verti-timeline.left-timeline .timeline-item.left .timeline-box:after {
  right: auto;
  left: -20px;
  border-left-color: transparent;
  border-right-color: #0078d4;
}
.verti-timeline.left-timeline .timeline-item.left .timeline-box::before {
  right: auto;
  left: -55px;
}

[dir=rtl] .hori-timeline .swiper-wrapper {
  direction: rtl;
}

.categories-filter .list-inline-item {
  position: relative;
  margin-right: 0;
}
.categories-filter .list-inline-item a {
  display: block;
  color: var(--bs-body-color);
  font-weight: 600;
  padding: 8px 15px;
  margin: 7px;
  cursor: pointer;
  border-radius: 4px;
}
.categories-filter .list-inline-item a.active {
  color: #0078d4;
  font-weight: 700;
}
.categories-filter .list-inline-item:after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--bs-text-muted);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
}
.categories-filter .list-inline-item:last-child:after {
  display: none;
}

.gallery-box {
  position: relative;
  text-align: center;
  overflow: hidden;
  border: 1px solid var(--bs-border-color);
  box-shadow: 0 2px 3px var(--bs-gray-100);
}
.gallery-box .gallery-container {
  position: relative;
  overflow: hidden;
  border-radius: 4px 4px 0px 0px;
}
.gallery-box .gallery-container a {
  display: block;
}
.gallery-box .gallery-container .gallery-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  opacity: 0;
  background: rgba(0, 120, 212, 0.2);
  visibility: hidden;
  overflow: hidden;
  transition: all 0.4s ease-in-out 0s;
}
.gallery-box .gallery-container .gallery-overlay .overlay-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
.gallery-box .gallery-img {
  transition: all 0.2s ease-in-out;
}
.gallery-box .box-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -28px;
  background-color: var(--bs-card-bg);
  transition: all 0.2s;
}
.gallery-box .box-content .title {
  font-size: 14px;
  margin-bottom: 4px;
}
.gallery-box .box-content .post {
  margin: 0;
  opacity: 0;
  transition: all 0.2s;
  color: var(--bs-text-muted);
}
.gallery-box:hover .box-content {
  bottom: 0;
}
.gallery-box:hover .box-content .post {
  opacity: 1;
}
.gallery-box:hover .gallery-img {
  transform: translateY(-28px);
}
.gallery-box:hover .gallery-overlay {
  opacity: 1;
  visibility: visible;
}

.profile-bg {
  height: 210px;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: transparent;
}

.faq-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.pricing-box {
  border-top: 2px solid #0078d4;
}
.pricing-box .pricing-features {
  color: var(--bs-text-muted);
}
.pricing-box .pricing-features li {
  padding: 4px 0;
}

.error-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 130px;
}
@media (max-width: 1199.98px) {
  .error-text {
    font-size: 72px;
  }
  .error-text .persona-lg {
    width: 48px;
  }
}

.dropdown-toggle::after {
  display: none;
}

i-feather {
  height: 20px !important;
  width: 20px !important;
}

#side-menu i-feather {
  height: 16px !important;
  width: 16px !important;
  line-height: 16px;
}

.fc .fc-col-header-cell-cushion {
  padding: 8px 4px !important;
  color: #495057 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #0078d4;
  border-color: #0078d4;
  margin-bottom: 0;
  margin-right: 0;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  color: #fff;
  margin-bottom: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  gap: 5px;
}

.multi_default .ng-value-icon {
  display: none !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
  font-size: 16px;
  line-height: 16px;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #74788d !important;
}

/**
*   Projects
*/
.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
  background-color: rgba(0, 120, 212, 0.2);
  color: #0078d4;
  border: 1px solid rgba(0, 120, 212, 0.2) !important;
  border-color: rgba(0, 120, 212, 0.2);
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator {
  border: 1px solid rgba(0, 120, 212, 0.2) !important;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator,
aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable a:hover .step-indicator {
  font-size: 20px;
  color: #0078d4 !important;
  border: 1px solid #99c9ee;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
  border: 1px solid #99c9ee;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
  width: 75%;
  height: 2px;
  left: 62%;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
  background-color: #99c9ee;
  color: #0078d4;
  border-color: #99c9ee;
}

/**
*   Profile page
*/
.swiper-sliders .swiper-button-next,
.swiper-sliders .swiper-button-prev {
  height: 30px !important;
  width: 30px !important;
  margin: auto 0;
  color: #fff !important;
  background-color: #039ef5;
}

.swiper-sliders .swiper-button-next,
.swiper-sliders .swiper-container-rtl .swiper-button-prev {
  right: 0 !important;
}

.swiper-sliders .swiper-button-prev,
.swiper-sliders .swiper-container-rtl .swiper-button-next {
  left: 0 !important;
}

.swiper-sliders .swiper-button-next:after,
.swiper-sliders .swiper-button-prev:after {
  font-size: 18px !important;
}

.swiper-sliders .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 !important;
}

/**
* Faqs Page
*/
.general_faq .card-header {
  padding: 0;
  border: none;
}

.general_faq .card {
  box-shadow: none;
  margin: 0;
  border: none;
}

.general_faq .card-body {
  border-bottom: 1px solid #eff0f2;
}

.general_faq .btn-link {
  width: 100%;
  padding: 0;
  border: none;
}

.general_faq .collapsed .accordion-button {
  background-color: transparent;
  color: #495057;
  box-shadow: none;
}

.accordion_exp .card {
  border: 1px solid #eff0f2 !important;
}

.accordion_exp .card-body {
  border: none !important;
}

.datatables thead tr th {
  position: relative;
}
.datatables thead tr th:before {
  content: "\f0143";
  position: absolute;
  right: 0;
  top: 7px;
  font: normal normal normal 24px/1 "Material Design Icons";
  opacity: 0.3;
}
.datatables thead tr th:after {
  content: "\f0140";
  position: absolute;
  right: 0;
  top: 14px;
  opacity: 0.3;
  font: normal normal normal 24px/1 "Material Design Icons";
}
.datatables thead tr .asc:before {
  opacity: 1;
}
.datatables thead tr .desc:after {
  opacity: 1;
}

/**
*   Ui Elements
*/
.alert-dismissible .btn-close {
  font-size: 10px;
  padding: 1.05rem 1.25rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.custom-alert .close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  color: #000;
}
.custom-alert .close span {
  display: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel-dark .carousel-indicators li {
  background-color: #000;
}

.col-xl-3:last-child .card .overflow-hidden {
  background-color: transparent !important;
}

/**
* Extended Ui
*/
.vimeo_video iframe {
  width: 100%;
}

.vimeo_video #player {
  max-width: 100% !important;
}

.ngx-slider .ngx-slider-pointer {
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
  top: -7px !important;
  right: -10px !important;
  background-color: #fff !important;
  z-index: 3 !important;
  border-radius: 50% !important;
  border: 1px solid #adb5bd;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-icon.swal2-question {
  border-color: #5fd0f3 !important;
  color: #5fd0f3 !important;
}

.swal2-icon.swal2-warning {
  border-color: #f7cc53 !important;
  color: #f7cc53 !important;
}

.stars {
  font-size: 30px;
  color: #f7cc53;
}

.star {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: #f7cc53;
}

.star_fill {
  color: #adb5bd;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f7cc53;
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.swiper-pagination {
  transform: translateX(-50%) !important;
}

swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle {
  line-height: 0;
  padding: 0 2px !important;
}

/**
* Forms
*/
.color_picker {
  width: auto;
  margin: 0 auto;
  display: inline-block;
}

.color_picker .input-group-text {
  padding: 20px;
}

/**
* Icons
*/
.icon-demo-content i-feather {
  width: 40px !important;
  height: 40px !important;
  line-height: 36px;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.icon-demo-content span {
  margin-top: 8px;
  display: inline-block;
}

body[data-layout-mode=dark] {
  /**
    * Projects
    */
  /**
    *   Faqs Page
    */
  /**
    *  Ui Elements
    */
  /**
  * Range Slider
  */
}
body[data-layout-mode=dark] .fc-theme-standard .fc-scrollgrid,
body[data-layout-mode=dark] .fc-theme-standard td,
body[data-layout-mode=dark] .fc-theme-standard th {
  border: 1px solid #043a5a;
}
body[data-layout-mode=dark] .fc .fc-daygrid-day-number,
body[data-layout-mode=dark] .fc .fc-col-header-cell-cushion {
  color: #fff;
}
body[data-layout-mode=dark] .fc .fc-col-header-cell-cushion {
  color: #e2e5e8 !important;
}
body[data-layout-mode=dark] .ng-select.ng-select-opened > .ng-select-container {
  background: #033350;
}
body[data-layout-mode=dark] .ng-select .ng-select-container {
  background-color: #033350;
  border: 1px solid #043a5a;
  color: #fff;
}
body[data-layout-mode=dark] .ng-dropdown-panel {
  background-color: #033350;
  border: 1px solid #043a5a;
}
body[data-layout-mode=dark] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
  background-color: #043a5a;
}
body[data-layout-mode=dark] .dropzone {
  background-color: #033350 !important;
  border-color: #043a5a !important;
}
body[data-layout-mode=dark] .dropzone .dz-message {
  background-color: #033350 !important;
  color: #fff !important;
}
body[data-layout-mode=dark] .general_faq .collapsed .accordion-button {
  color: #fff;
  border-bottom: 1px solid #043a5a;
  box-shadow: none;
  background-color: transparent;
}
body[data-layout-mode=dark] .general_faq .card-body {
  border-bottom: 1px solid #043a5a;
}
body[data-layout-mode=dark] .general_faq .card {
  box-shadow: none;
}
body[data-layout-mode=dark] .alert-outline.custom-alert .close,
body[data-layout-mode=dark] .alert-top-border.custom-alert .close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  color: #000;
}
body[data-layout-mode=dark] .alert-outline.custom-alert .close span,
body[data-layout-mode=dark] .alert-top-border.custom-alert .close span {
  display: none;
}
body[data-layout-mode=dark] .ngx-slider .ngx-slider-bar {
  background-color: #033350;
}
body[data-layout-mode=dark] .form-control.disabled + .textarea-label .textarea-label-value,
body[data-layout-mode=dark] .form-control:disabled + .textarea-label .textarea-label-value {
  background-color: #033350;
}
body[data-layout-mode=dark] .form-floating .textarea-label-value {
  background-color: #033350;
  color: #7e93a0;
}

body * {
  outline: none;
}

.switch-pane {
  color: white;
}

@media screen and (max-width: 425px) {
  .otp-input {
    width: 60px !important;
    height: 60 !important;
  }
}
@media screen and (max-width: 1140px) {
  .table-md {
    font-size: 12px;
  }
}
.toast-container {
  background-image: linear-gradient(purple, red) !important;
  border-radius: 22px !important;
  box-shadow: -5px 8px 16px 0px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 2px !important;
}

.toast-container .toast {
  margin: 2px !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  background-color: #fff !important;
}

.toast-success {
  background-image: none !important;
}

.toast-success::after {
  position: absolute !important;
  top: 14px !important;
  left: 15px !important;
  color: #fff !important;
  font-size: 1.5em !important;
}

.toast-title {
  color: white !important;
  font-size: 16px !important;
}

.toast-message {
  color: #8f8f8f !important;
}

.text-italic {
  font-style: italic;
}

.tag-container {
  color: var(--bs-body-color);
  background-color: var(--bs-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-input-border-color);
  border-radius: 4px;
}

.tag-container tag-input {
  min-height: 78px;
  padding-top: 32px;
  padding-left: 8px;
  padding-right: 8px;
}
.tag-container tag-input .ng2-tag-input,
.tag-container tag-input .ng2-tag-input--focused {
  border: none !important;
}
.tag-container tag-input .ng2-tags-container {
  gap: 2px;
}
.tag-container tag-input tag {
  height: 34px !important;
  margin: 0 !important;
}

.tags-form-control {
  padding: 0 5px;
  overflow: hidden;
}
.tags-form-control div.ng2-tags-container {
  align-items: center;
}
.tags-form-control div.ng2-tags-container tag {
  height: 30px;
  margin: 0;
}
.tags-form-control div.ng2-tag-input {
  margin: 0;
  padding: 0;
  border: none;
}
.tags-form-control div.ng2-tag-input.ng2-tag-input--focused {
  border: none;
}

.language-bash {
  margin: 0 !important;
}

.no-sorting-column:before {
  display: none;
}
.no-sorting-column:after {
  display: none;
}

.main-content {
  max-height: 100vh;
  overflow: auto;
}

.footer {
  background-color: var(--bs-body-bg);
  z-index: 3;
}

@media (max-width: 1399.98px) {
  .person-general-card-title {
    font-size: 14px;
  }
}
@media (max-width: 1199.98px) {
  .person-general-card-title {
    font-size: 12px;
  }
}

@media (max-width: 575.98px) {
  .composer-body {
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .api-keys-header {
    flex-direction: column;
    align-items: end !important;
  }
  .api-keys-header .text {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 1199.98px) {
  .account-settings-row {
    flex-direction: column;
    align-items: start !important;
  }
}

@media (max-width: 575.98px) {
  .composer-header-row {
    flex-direction: column;
    align-items: start !important;
  }
  .composer-header-row app-ai-system-selector {
    display: block;
    margin: 20px 0;
  }
}

.cover-image {
  object-fit: cover;
}

.pointer {
  cursor: pointer;
}

.custom-toast {
  color: var(--bs-body-color);
  background-color: white;
}

body[data-layout-mode=dark] .custom-toast {
  background-color: var(--bs-body-bg);
  box-shadow: 0px 0px 4px 0px #3c3c3c;
}

.fz-13 {
  font-size: 13px;
}

.fz-16 {
  font-size: 16px;
}

.form-floating > label {
  z-index: 2;
}

.form-floating .textarea-label {
  opacity: 1;
}
.form-floating .textarea-label-value {
  padding-top: 8px;
  padding-left: 12px;
  margin-top: -8px;
  margin-left: -12px;
  background-color: white;
}
.form-floating > .form-control:focus ~ label.textarea-label, .form-floating > .form-control:not(:placeholder-shown) ~ label.textarea-label, .form-floating > .form-control-plaintext ~ label.textarea-label, .form-floating > .form-select ~ label.textarea-label {
  opacity: 1;
  color: rgba(73, 80, 87, 0.6);
}
.form-floating > textarea.form-control::placeholder {
  color: revert;
}
.form-floating > textarea.form-control:not(:focus)::placeholder {
  color: transparent;
}
.form-floating .form-control.disabled + .textarea-label .textarea-label-value,
.form-floating .form-control:disabled + .textarea-label .textarea-label-value {
  background-color: #f5f6f8;
}

.disabled-by-role {
  opacity: 0.8;
  cursor: default;
}
.disabled-by-role:disabled {
  pointer-events: auto;
}
.disabled-by-role:active, .disabled-by-role:focus {
  pointer-events: none;
}
.disabled-by-role:active.btn-outline-primary, .disabled-by-role:focus.btn-outline-primary {
  background: none;
  color: #0078d4;
}
.disabled-by-role * {
  pointer-events: none;
}