// Plugins
@import 'custom/plugins/icons';

.icon-size-15 {
  width: 15px !important;
  height: 15px !important;
}

.icon-size-80 {
  width: 80px !important;
  height: 80px !important;
}

.icon-size-70 {
  width: 70px !important;
  height: 70px !important;
}