//
// vector-maps.scss
//

.jvm-tooltip {
  border-radius: 3px;
  background-color: $primary;
  font-family: $font-family-base;
  box-shadow: $box-shadow-lg;
  padding: 3px 5px;
}
