//
// alerts.scss
//

.alert-top-border {
  background-color: var(--#{$prefix}card-bg);
  border-color: var(--#{$prefix}light);
  border-top: 2px solid $light;
}

.alert-outline {
  background-color: var(--#{$prefix}card-bg);
}

.alert-light {
  color: $text-muted;
}

@each $color, $value in $theme-colors {
  .alert-top-border {
    &.alert-#{$color} {
      border-top-color: $value;
      color: $value;
    }
  }

  .alert-outline {
    &.alert-#{$color} {
      border: 1px solid rgba($value, 0.8);
      color: $value;
    }
  }
}

.alert-top-border,
.alert-outline {
  &.alert-light {
    color: $text-muted;
  }
  &.alert-dark {
    color: var(--#{$prefix}dark);
    border-top-color: var(--#{$prefix}border-color);
  }
  &.alert-outline {
    border-color: var(--#{$prefix}border-color);
  }
}

ngb-alert {
  width: fit-content;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 105;
}
