[data-layout-mode='dark'] {
  // Dark theme Layout
  $gray-100: #03273c;
  $gray-200: #032d46;
  $gray-300: #043a5a;
  $gray-400: #7e93a0;
  $gray-500: #adb5bd;
  $gray-600: #eff0f2;
  $gray-700: #e2e5e8;
  $gray-800: #f5f6f8;
  $gray-900: #f8f9fa;

  $grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  );

  // Prefix for :root CSS variables
  @each $color, $value in $grays {
    --#{$prefix}gray-#{$color}: #{$value};
  }

  // body
  --#{$prefix}body-bg: #{darken($gray-200, 2%)};
  --#{$prefix}body-bg-rgb: #{to-rgb($gray-300)};
  --#{$prefix}body-color: #{$gray-400};
  --#{$prefix}body-color-rgb: #{to-rgb($gray-700)};
  --#{$prefix}text-muted: #{$gray-400};
  --#{$prefix}border-color: #{$gray-300};
  --#{$prefix}light-rgb: #{to-rgb($gray-300)};
  --#{$prefix}light: #{$gray-300};
  --#{$prefix}dark-rgb: #{to-rgb($gray-200)};
  --#{$prefix}dark: #{$gray-200};
  --#{$prefix}white: #{$gray-200};

  //heading
  --#{$prefix}heading-color: #{$gray-600};

  //footer
  --#{$prefix}footer-color: #{gray-200};
  --#{$prefix}footer-shadow: 0 0px 4px rgba(#{to-rgb($gray-700)}, 0.12);

  //form
  --#{$prefix}input-bg: #{lighten($gray-200, 2%)};
  --#{$prefix}input-border-color: #{$gray-300};
  --#{$prefix}form-check-input-border: #{$gray-300};
  --#{$prefix}input-focus-border-color: #{$gray-300};
  --#{$prefix}form-select-focus-border-color: #{lighten($gray-300, 1%)};

  // Horizontal nav
  --#{$prefix}topnav-bg: #{lighten($gray-200, 1%)};

  .text-dark {
    --#{$prefix}dark-rgb: #{to-rgb($gray-800)};
  }

  .card,
  .noUi-handle {
    --#{$prefix}card-bg: #{$gray-200};
    --#{$prefix}card-border-color: #{lighten($gray-200, 4%)};
    --#{$prefix}card-box-shadow: 0 2px 3px #{darken($gray-200, 4%)};
    --#{$prefix}card-cap-bg: #{$gray-200};
    --#{$prefix}card-header-bg: #{$gray-300};
  }

  .btn-light {
    --#{$prefix}btn-color: #{$gray-600};
    --#{$prefix}btn-bg: #{$gray-300};
    --#{$prefix}btn-border-color: #{$gray-300};
  }

  .btn-outline-light {
    --#{$prefix}btn-color: #{$gray-600};
    --#{$prefix}btn-border-color: #{$gray-300};
  }

  .btn-light,
  .btn-outline-light {
    --#{$prefix}btn-hover-color: #{$gray-600};
    --#{$prefix}btn-hover-bg: #{$gray-300};
    --#{$prefix}btn-hover-border-color: #{$gray-300};
    --#{$prefix}btn-active-color: #{$gray-600};
    --#{$prefix}btn-active-bg: #{$gray-300};
    --#{$prefix}btn-active-border-color: #{$gray-300};
    --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($gray-300)};
  }

  .btn-dark {
    --#{$prefix}btn-bg: #{lighten($gray-200, 2%)};
    --#{$prefix}btn-border-color: #{lighten($gray-200, 2%)};
    --#{$prefix}btn-hover-bg: #{lighten($gray-200, 2%)};
  }

  .btn-outline-dark {
    --#{$prefix}btn-color: #{$gray-600};
    --#{$prefix}btn-border-color: #{lighten($gray-200, 2%)};
  }

  .btn-dark,
  .btn-outline-dark {
    --#{$prefix}btn-hover-border-color: #{lighten($gray-200, 2%)};
    --#{$prefix}btn-active-bg: #{lighten($gray-200, 2%)};
    --#{$prefix}btn-active-border-color: #{lighten($gray-200, 2%)};
    --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($gray-300)};
    --#{$prefix}btn-hover-bg: #{$gray-300};
  }

  .dropdown-menu,
  .flatpickr-calendar,
  .choices {
    --#{$prefix}dropdown-color: #{$gray-500};
    --#{$prefix}dropdown-bg: #{lighten($gray-200, 2.5%)};
    --#{$prefix}dropdown-border-color: #{$gray-300};
    --#{$prefix}dropdown-box-shadow: 0 2px 3px #{to-rgb($gray-200)};
    --#{$prefix}dropdown-link-color: #{$gray-500};
    --#{$prefix}dropdown-divider-bg: #{$gray-300};
    --#{$prefix}dropdown-link-hover-bg: #{lighten($gray-200, 5%)};
    --#{$prefix}dropdown-link-hover-color: #{$gray-500};
    --#{$prefix}dropdown-link-active-bg: #{$gray-300};
  }

  .list-group {
    --#{$prefix}list-group-color: #{$gray-400};
    --#{$prefix}list-group-bg: #{$gray-200};
    --#{$prefix}list-group-border-color: #{$gray-300};
  }

  .breadcrumb {
    --#{$prefix}breadcrumb-item-active-color: #{$gray-400};
  }

  .table,
  .gridjs-table {
    --#{$prefix}table-border-color: #{$gray-300};
    --#{$prefix}table-color: #{$gray-400};
    --#{$prefix}table-striped-bg: #03304b;
    --#{$prefix}table-striped-color: #{$gray-400};
    --#{$prefix}table-active-bg: #{lighten($gray-200, 1.5%)};
    --#{$prefix}table-active-color: #{$gray-400};
    --#{$prefix}table-hover-bg: #{lighten($gray-200, 1.5%)};
    --#{$prefix}table-hover-color: #{$gray-400};
  }

  .table-dark {
    --#{$prefix}table-bg: #{$gray-300};
    --#{$prefix}table-border-color: #{lighten($gray-200, 5%)};
  }

  .table-light {
    --#{$prefix}table-bg: #{lighten($gray-200, 2.5%)};
    --#{$prefix}table-border-color: #{lighten($gray-200, 5%)};
    --#{$prefix}table-color: #{$gray-400};
  }

  .modal,
  .alertify {
    --#{$prefix}modal-bg: #{$gray-200};
    --#{$prefix}modal-border-color: #{$gray-300};
    --#{$prefix}modal-header-border-color: #{$gray-300};
    --#{$prefix}modal-footer-border-color: #{$gray-300};
  }

  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl,
  .offcanvas-xxl {
    --#{$prefix}offcanvas-bg: #{$gray-200};
    --#{$prefix}offcanvas-border-color: #{lighten($gray-200, 4%)};
  }

  .progress {
    --#{$prefix}progress-bg: #{$gray-300};
  }

  .accordion {
    --#{$prefix}accordion-border-color: #{$gray-300};
    --#{$prefix}accordion-color: #{$gray-400};
    --#{$prefix}accordion-btn-color: #{$gray-600};
    --#{$prefix}accordion-active-bg: rgba(#{to-rgb($primary)}, 0.2);
  }

  .nav-tabs {
    --#{$prefix}nav-tabs-link-active-bg: #{$gray-200};
    --#{$prefix}nav-tabs-link-active-border-color: #{$gray-300};
    --#{$prefix}nav-tabs-border-color: #{$gray-300};
    --#{$prefix}nav-tabs-link-hover-border-color: #{$gray-300};
    --#{$prefix}nav-tabs-link-active-color: #{$primary};
  }

  .popover {
    --#{$prefix}popover-bg: #{$gray-300};
    --#{$prefix}popover-body-color: #{$gray-600};
    --#{$prefix}popover-border-color: #{lighten($gray-300, 4%)};
    --#{$prefix}popover-header-bg: #{$gray-300};
    --#{$prefix}popover-header-color: #{$gray-600};
  }

  .pagination,
  .gridjs-pagination {
    --#{$prefix}pagination-bg: #{$gray-200};
    --#{$prefix}pagination-border-color: #{$gray-300};
    --#{$prefix}pagination-color: #{$gray-500};
    --#{$prefix}pagination-hover-bg: #{lighten($gray-200, 2%)};
    --#{$prefix}pagination-hover-color: #{$primary};
    --#{$prefix}pagination-hover-border-color: #{$gray-300};
    --#{$prefix}pagination-disabled-bg: #{lighten($gray-200, 2%)};
    --#{$prefix}pagination-disabled-color: #{$gray-500};
    --#{$prefix}pagination-disabled-border-color: #{$gray-300};
  }

  .alert {
    --#{$prefix}alert-dark: #{$gray-300};
  }
}
